import HeaderLayout from "../layouts/HeaderLayout";
import DoctorsBonusListContainer from '../containers/DoctorsBonusListContainer';
import ReportDoctorsBonusListContainer from '../containers/ReportDoctorsBonusListContainer';
import AdminHeaderLayout from './../layouts/AdminHeaderLayout';
import AdminDebetListReportContainer from './../containers/AdminDebetListReportContainer';

const AdminDebetListReportPage = () => {

    return (
        <>
            <AdminHeaderLayout />
            <AdminDebetListReportContainer />
        </>
    )
}

export default AdminDebetListReportPage