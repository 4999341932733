
export const csvToJsonDebetListHelper = (data, collectionType, factQtyField) => {
  let res = {};

  if (collectionType === 'debets') {
    const { itemId, month, year, contragent, manager, realization, payed, balanceToPay, debtPrevMonth, totalDebt } = data;
    // if (itemId === '') return console.log('resItem is empty')

    res = {
      itemId: itemId,
      month: Number(month),
      year: Number(year),
      contragent: contragent,
      manager: { id: Number(manager) },
      realization: Math.round(realization),
      payed: Math.round(payed),
      balanceToPay: Math.round(balanceToPay),
      debtPrevMonth: Math.round(debtPrevMonth),
      totalDebt: Math.round(totalDebt),
    }
  }

  if (collectionType === 'products-quantities') {
    const { itemId, year, month, productArticle, manager } = data;
    const factQty = Number(data[factQtyField]);

    res = {
      itemId: itemId,
      year: Number(year),
      month: Number(month),
      manager: { id: Number(manager) },
      productArticle: productArticle,
      [factQtyField]: factQty,
    };
  }

  return res;
};

export const isDataMatching = (item1, item2, fieldsToCompare, excludeFields = []) => {
  for (const field of fieldsToCompare) {
    if (excludeFields.includes(field)) continue;

    if (field === 'itemId') {
      let itemId1 = `${item1.manager.id}-${item1.productArticle}-${item1.month}-${item1.year}`;
      let itemId2 = item2.itemId;

      if (itemId1 !== itemId2) {
        return false;
      }
    } else {
      if (item1[field] !== item2[field]) {
        return false;
      }
    }
  }
  return true;
}


// export const csvToJsonProductQtyListHelper = (data) => {
//   const { year, month, productArticle, factQty, managerId } = data;

//   const res = {
//     year: year,
//     month: month,
//     productArticle: productArticle,
//     factQty: factQty,
//     manager: managerId
//   }

//   return res
// }