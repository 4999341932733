// import { getAreaListApi } from "../../api/areaListApi"
// import { getCityListApi } from "../../api/cityListApi"
import { getManagerListApi } from "../../api/managerListApi"
import { addOrderApi, getMonthOrderListApi, getOrderListShopApi, getPromocodesJson, getYearOrderListApi } from "../../api/orderListApi"
import { CURRENT_MONTH_SET, ORDER_LIST_LOAD_SUCCESSFUL } from "../reducers/orderListReducer"
import { loadAreaListAction } from "./areaListActions"
import { loadCityListAction } from "./cityListActions"
import { loadManagerListAction } from "./managerListActions"
// import { addPromocodeApi, getPromocodesApi } from './../../api/promocodesApi';
import { loadPromocodeListAction } from './promocodeActions';
import { getOrdersJson, getOrdersFromDb } from './../../api/orderListApi';
import { loginApi } from './../../auth/loginApi';
// import { addOrderRuApi, getOrderListRuShopApi } from "../../api/orderListRuApi"
// import { getOrdersRuFromDb } from './../../api/orderListRuApi';
import { getAdminMonthOrderListApi } from './../../api/orderListApi';

export const loadOrderListAction = (res) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_LIST_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}

export const getCurrentMonthAction = () => {
    return (dispatch) => {
        const now = new Date()
        const currentMonth = now.getMonth()
        dispatch({
            type: CURRENT_MONTH_SET,
            payload: currentMonth,
        })
    }
}

export const loadAllListAction = () => {
    return async (dispatch) => {
        // const resCities = await getCityListApi()
        // const resAreas = await getAreaListApi()
        // const resManagers = await getManagerListApi()
        // const params = {from: "01.06.2021"}
        // const resOrders = await getYearOrderListApi('2021')
        // console.log("🚀 ~ file: orderListActions.js ~ line 39 ~ return ~ resOrders", resOrders)
        // const resPromocodes = await getPromocodesApi()
        // await addOrderApi()
        // const resPromocodesJSON = await getPromocodesJson()

        // dispatch(loadCityListAction(resCities))
        // dispatch(loadAreaListAction(resAreas))
        // dispatch(loadManagerListAction(resManagers))
        // dispatch(loadOrderListAction(resOrders))
        // dispatch(loadPromocodeListAction(resPromocodesJSON))
    }
}

// export const loadOrderListByMonthAction = (year, month) => {
//     return async (dispatch) => {

//     }
// }

export const loadMonthOrderListAction = (userData, year, month) => {
    return async (dispatch) => {
        const data = await getMonthOrderListApi(userData, year, month)
        dispatch({
            type: ORDER_LIST_LOAD_SUCCESSFUL,
            payload: data,
        })
    }
}

export const loadAdminMonthOrderListAction = (userData, year, month, location, brand) => {
    return async (dispatch) => {
        const data = await getAdminMonthOrderListApi(userData, year, month, location, brand)
        dispatch({
            type: ORDER_LIST_LOAD_SUCCESSFUL,
            payload: data,
        })
    }
}

