import { combineReducers } from 'redux'
import { areaListReducer } from './areaListReducer'
import { cityListReducer } from './cityListReducer'
import { managerListReducer } from './managerListReducer'
import { orderListReducer } from './orderListReducer'
import { promocodeReducer } from './promocodeReducer';
import { authenticationReducer } from './authenticationReducer';
import settingsReducer from './settingsReducer'
import snackbarReducer from './snackbarReducer';
import { debetsReducer } from './debetsReducer';
import { productQtyListReducer } from './productQtyListReducer'
import { promocodeCommentsReducer } from './promocodeCommentsReducer'

const CombinedReducer = combineReducers({
    orderList: orderListReducer,
    managerList: managerListReducer,
    // areaList: areaListReducer,
    // cityList: cityListReducer,
    promocode: promocodeReducer,
    debets: debetsReducer,
    productQtyList: productQtyListReducer,
    // settings: settingsReducer,
    authentication: authenticationReducer,
    snackbar: snackbarReducer,
    promocodeComments: promocodeCommentsReducer
})

export default CombinedReducer;