import HeaderLayout from "../layouts/HeaderLayout";
import DoctorsBonusListContainer from './../containers/DoctorsBonusListContainer';
import ReportDoctorsBonusListContainer from './../containers/ReportDoctorsBonusListContainer';
import OrderListPerMonthContainer from './../containers/OrderListPerMonthContainer';
import AdminHeaderLayout from './../layouts/AdminHeaderLayout';
import AdminOrderListPerMonthContainer from './../containers/AdminOrderListPerMonthContainer';
import AdminDoctorsBonusReportContainer from './../containers/AdminDoctorsBonusReportContainer';

const AdminDoctorsBonusReportPage = () => {

    return (
        <>
            <AdminHeaderLayout />
            <AdminDoctorsBonusReportContainer />
        </>
    )
}

export default AdminDoctorsBonusReportPage