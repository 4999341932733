import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { loadBonusDataByPeriodAction, loadOrderDataByPeriodAction, loadPromocodeDataAction } from '../data/actions/bonusSystemActions';
import { getBonusDataByFilter, getOrdersDataByFilter, getPromocodeDataByDoctor } from './../data/selectors/bonusSystemSelector';
import { CircularProgress } from '@material-ui/core';
import EditBonusItem from '../views/bonusSystem/EditBonusItem';
import { loadPromocodeCommentItemAction } from '../data/actions/promocodeCommentsActions';
import { promocodeCommentListSelector } from '../data/selectors/promocodeCommentsSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const BonusSystemItemContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const promocodeFromState = location.state?.promocode || '';
    const doctorFromState = location.state?.doctor || '';
    const yearFromState = location.state?.year || '';
    const monthFromState = location.state?.month || '';
    const locationFrom = location.state?.from || '';

    const userData = useSelector(userDataSelector);

    const [year, setYear] = useState(userData.settingsData.yearData);
    const [month, setMonth] = useState(userData.settingsData.monthData + 1);
    const [doctorFilter, setDoctorFilter] = useState('');
    const [promocodeFilter, setPromocodeFilter] = useState(promocodeFromState || '');
    const [loading, setLoading] = useState(false); // Добавьте состояние загрузки

    const { selectedBonusItem, previousMonths } = useSelector(getBonusDataByFilter(year, month, promocodeFilter));
    const { filteredItems, selectedItem } = useSelector(getPromocodeDataByDoctor(doctorFilter, promocodeFilter));
    const selectedManagerId = selectedItem ? selectedItem.manager.id : userData.managerId;
    const { selectedData, saleQtySum, salePriceSum, productsByQuantity } = useSelector(getOrdersDataByFilter(year, month, promocodeFilter));
    const promocodeComments = useSelector(promocodeCommentListSelector)

    useEffect(() => {
        if (locationFrom !== '/newPromocodes') {
            dispatch(loadPromocodeDataAction(userData));
        }
    }, [userData]);

    useEffect(() => {
        const loadAsyncData = async () => {
            setLoading(true); // Начать загрузку
            if (locationFrom === '/newPromocodes' && promocodeFromState !== '' && selectedManagerId) {
                setPromocodeFilter(promocodeFromState);
                setDoctorFilter(doctorFromState)
                await Promise.all([
                    dispatch(loadBonusDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter)), // Pass promocodeFilter
                    dispatch(loadOrderDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter))
                ]);
                addUserLogDateApi(userData, `/bonusSystemItem/ -push -newPromocodes ${promocodeFilter}`)
                history.replace(history.location.pathname, {});
            }
            if (history.action === 'PUSH' && (locationFrom === '/bonusSystem' || locationFrom === '/admin/bonusSystem')) {
                setPromocodeFilter(promocodeFromState);
                setDoctorFilter(doctorFromState)
                await Promise.all([
                    dispatch(loadBonusDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter)), // Pass promocodeFilter
                    dispatch(loadOrderDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter))
                ]);
                addUserLogDateApi(userData, `/bonusSystemItem/ -push ${promocodeFilter}`)
                history.replace(history.location.pathname, {});
            }
            if (promocodeFilter !== '') {
                if (history.action === 'REPLACE' && locationFrom === '') {
                    await Promise.all([
                        dispatch(loadBonusDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter)), // Pass promocodeFilter
                        dispatch(loadOrderDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter))
                    ]);
                    addUserLogDateApi(userData, `/bonusSystemItem/ -${history.action} ${promocodeFilter}`)
                }
                if (history.action === 'POP') {
                    await Promise.all([
                        dispatch(loadBonusDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter)), // Pass promocodeFilter
                        dispatch(loadOrderDataByPeriodAction(userData, year, month, selectedManagerId, promocodeFilter))
                    ]);
                    addUserLogDateApi(userData, `/bonusSystemItem/ -${history.action} ${promocodeFilter}`)
                }
            }
            setLoading(false); // Завершить загрузку после завершения всех действий
        };
        if (selectedManagerId) {
            loadAsyncData();
        }
    }, [userData, month, year, promocodeFilter]);

    useEffect(() => {
        if (promocodeFilter !== '') {
            dispatch(loadPromocodeCommentItemAction(userData, promocodeFilter))
        }
    }, [promocodeFilter]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', width: '100%', height: '100vh'
        }}>
            <CircularProgress />
        </div>
    }

    return (
        <EditBonusItem
            userData={userData}
            yearFilter={year}
            setYearFilter={setYear}
            monthFilter={month}
            setMonthFilter={setMonth}
            doctorFilter={doctorFilter}
            setDoctorFilter={setDoctorFilter}
            filteredItems={filteredItems}
            selectedItem={selectedItem}
            promocodeFilter={promocodeFilter}
            setPromocodeFilter={setPromocodeFilter}
            saleQtySum={saleQtySum}
            salePriceSum={salePriceSum}
            productsByQuantity={productsByQuantity}
            selectedBonusItem={selectedBonusItem}
            previousMonths={previousMonths}
            selectedData={selectedData}
            promocodeComments={promocodeComments}
        />
    );
};

export default BonusSystemItemContainer;