import React, { useState, useEffect } from 'react';
import SummaryPanel from './SummaryPanel';
import DataTable from './DataTable';
import FilterPanel from './FilterPanel';
import { useSelector } from 'react-redux';
import { getBonusDataByByYearAndMonthAndRegion, getBonusPaymentsDataByByYearAndMonth, getOrderDataByYearAndMonthAndRegion, getTotalSumsByYearAndMonth } from '../../data/selectors/bonusSystemSelector';
import { Paper } from '@material-ui/core';
import DataPaymentsTable from './DataPaymentsTable';
import PaymentsFilterPanel from './PaymentsFilterPanel';

const BonusPaymentsList = ({
  userData,
  managerFilter,
  setManagerFilter,
  yearFilter,
  setYearFilter,
  monthFilter,
  setMonthFilter,
  bonusSum,
  promocodeOrdersSum,
  greyZoneOrdersSum,
  bonusRecommendedSum
}) => {
  const managerList = userData.managerIdList;

  const [regionFilter, setRegionFilter] = useState('');
  const [showPreviousMonths, setShowPreviousMonths] = useState(false);
  const [cityFilter, setCityFilter] = useState('');

  const { filteredItems } = useSelector(getBonusPaymentsDataByByYearAndMonth(yearFilter, monthFilter, managerFilter));
  // const { greyZoneOrdersSumByRegion } = useSelector(getOrderDataByYearAndMonthAndRegion(yearFilter, monthFilter, regionFilter, managerFilter));
  // const totalSumsByYearAndMonth = useSelector(getTotalSumsByYearAndMonth(managerFilter))

  // const totalSalesSum = promocodeOrdersSum + greyZoneOrdersSum
  // const totalGreyZoneBonus = greyZoneOrdersSum ? Math.round((10 / 100) * (greyZoneOrdersSum)) : 0
  // const finLimit = greyZoneOrdersSum ? Math.round((10 / 100) * (greyZoneOrdersSum)) + bonusSum : 0
  // const usedBonuses = finLimit - bonusRecommendedSum;

  useEffect(() => {
    // setRegionFilter(firstRegion);
    if (!managerFilter) {
      setManagerFilter(managerList[0].id)
    }
  }, [managerList]);

  // useEffect(() => {
  //   if (firstRegion) {
  //     setRegionFilter(firstRegion);
  //   }
  // }, [firstRegion]);

  return (
    <Paper elevation={0}>
      <Paper style={{ position: 'fixed', width: '100%', top: '64px', backgroundColor: '#fff', border: '1px solid #ccc', zIndex: 10 }}>
        {/* <SummaryPanel
          totalSalesSum={totalSalesSum}
          totalPromocodeSum={promocodeOrdersSum}
          totalPromocodeBonus={bonusSum}
          totalGreyZoneSum={greyZoneOrdersSum}
          totalGreyZoneBonus={totalGreyZoneBonus}
          totalBonusLimit={finLimit}
          usedBonuses={usedBonuses}
          totalSumsByYearAndMonth={totalSumsByYearAndMonth}
        /> */}
        <PaymentsFilterPanel
          managerFilter={managerFilter}
          setManagerFilter={setManagerFilter}
          managerList={managerList}
          yearFilter={yearFilter}
          monthFilter={monthFilter}
          setYearFilter={setYearFilter}
          setMonthFilter={setMonthFilter}
          // allRegions={uniqueRegionList}
          // regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
          cityFilter={cityFilter}
          setCityFilter={setCityFilter}
          // greyZoneOrdersSumByRegion={greyZoneOrdersSumByRegion}
          // filteredPromocodeOrdersSum={filteredPromocodeOrdersSum}
          // filteredBonusSum={filteredBonusSum}
          showPreviousMonths={showPreviousMonths}
          setShowPreviousMonths={setShowPreviousMonths}
        />
      </Paper>
      <DataPaymentsTable
        userData={userData}
        tableData={filteredItems}
        regionFilter={regionFilter}
        yearFilter={yearFilter}
        monthFilter={monthFilter}
        // greyZoneOrdersSumByRegion={greyZoneOrdersSumByRegion}
        // filteredPromocodeOrdersSum={filteredPromocodeOrdersSum}
        // filteredBonusSum={filteredBonusSum}
        // previousMonths={previousMonths}
        showPreviousMonths={showPreviousMonths}
      />
    </Paper>
  );
};

export default BonusPaymentsList;
