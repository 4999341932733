import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

const AdminProductQtyEditableField = ({ id, value, setEditedData }) => {
  const [editedValue, setEditedValue] = useState(value);

  const handleInputChange = (event) => {
    setEditedValue(event.target.value);
  };

  const handleSaveClick = () => {
    const data = { id, planQty: editedValue };
    setEditedData(data);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveClick();
    }
  };

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  return (
    <>
      <TextField
        value={editedValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        InputProps={{ disableUnderline: true }}
      />
    </>
  );
};

export default AdminProductQtyEditableField;
