import { createSelector } from 'reselect'
import { orderListItemsSelector } from './orderListItemsSelector';
import { managerSalesFinLimitSelector } from './managerListSelector';
import { userDataSelector } from './userDataSelector';
import { settingsDataSelector } from './settingsDataSelector';

export const promocodeListSelector = (state) => state.promocode.items
export const promocodeBonusListSelector = (state) => state.promocode.bonuses
export const uniqueBonusRegionsListSelector = (state) => state.promocode.uniqueRegions

export const newPromocodeListSelector = createSelector(
    [promocodeListSelector],
    (promocodeList) => {
        const newPromocodes = promocodeList.filter(item => item.activated === false)
        return newPromocodes
    }
)

export const allPromocodeListSelector = createSelector(
    [promocodeListSelector],
    (promocodeList) => {

        const arrays = Object.values(promocodeList)
        var res = [].concat.apply([], arrays);

        let sortedPromocodes = [...res];
        sortedPromocodes.sort((b, a) => {
            if (a.manager !== null && b.manager !== null && a.manager.id > b.manager.id) {
                return -1;
            }
            if (a.manager !== null && b.manager !== null && a.manager.id < b.manager.id) {
                return 1;
            }
            return 0;
        })
        return sortedPromocodes
    }
)

export const newPromocodeBonusListSelector = (year, month) => createSelector(
    [promocodeBonusListSelector],
    (promocodeBonusList) => {
        if (!promocodeBonusList) {
            return []; // Возвращаем пустой массив, если список пуст или undefined
        }
        const filterList = (item) => {
            return item.bonusMonth === month && item.bonusYear === year;
        }
        return promocodeBonusList.filter(filterList);
    }
)


export const newPromocodeBonusAllSumSelector = createSelector(
    [newPromocodeBonusListSelector, userDataSelector, managerSalesFinLimitSelector],
    (newPromocodeBonusList, userData, managerSalesFinLimit) => {
        // Проверяем, является ли newPromocodeBonusList массивом
        if (!Array.isArray(newPromocodeBonusList)) {
            // Если не является, возвращаем 0 или какое-то другое значение по умолчанию
            return { allBonusSum: 0, allBonusExtra: 0, allBonusTotal: 0, month: userData.settingsData.monthData, managerSalesFinLimit };
        }

        // Иначе выполняем вычисления
        const allBonusSum = newPromocodeBonusList.reduce((sum, item) => sum + item.bonusSum, 0);
        const allBonusExtra = newPromocodeBonusList.reduce((sum, item) => sum + item.bonusExtra, 0);
        const allBonusTotal = allBonusSum + allBonusExtra;
        const month = userData.settingsData.monthData;

        return { allBonusSum, allBonusExtra, allBonusTotal, month, managerSalesFinLimit };
    }
)


export const activatedPromocodeBonusListSelector = (archiveStatus) => createSelector(
    [promocodeBonusListSelector],
    (promocodeBonusList) => {
        const activatedList = promocodeBonusList.filter(item => item.activated === archiveStatus)
        return activatedList
    }
)