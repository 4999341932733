import SnackbarComponent from "../components/SnackbarComponent";
import BonusSystemContainer from "../containers/BonusSystemContainer";
import AdminHeaderLayout from "../layouts/AdminHeaderLayout";

const AdminBonusSystemPage = () => {

    return (
        <>
            <SnackbarComponent />
            <AdminHeaderLayout />
            {/* <Container> */}
            <BonusSystemContainer />
            {/* </Container> */}
        </>
    )
}

export default AdminBonusSystemPage