import DashboardManagerSalesContainer from './../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";
import HeaderLayout from "../layouts/HeaderLayout";
import { Redirect } from "react-router-dom";

const DashboardPage = () => {

    const userData = useSelector(userDataSelector)
    const role = userData ? userData.role : ''

    if (localStorage.getItem('user') && (role === 'regman' || role === 'admin' || role === 'admins' || role === 'adminview')) {

            return <Redirect to={{ pathname: '/admin/dashboard' }} />
        }

    return (
        <>
            <HeaderLayout />
            <DashboardManagerSalesContainer />
        </>
    )
}

export default DashboardPage