import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import ViewTableDoctorsBonusList from '../views/ViewTableDoctorsBonusList';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { loadStartAction } from '../data/actions/loadStartActions';
import { NEW_PROMOCODE_STATUS, ACTIVE_PROMOCODE_STATUS } from '../data/constants/statuses';
import Typography from '@material-ui/core/Typography';
import { newPromocodeListSelector, promocodeListSelector } from '../data/selectors/promocodeSelector';
import EditTablePromocodeList from '../views/EditTablePromocodeList';
import { loadManagerPromocodeListAction } from './../data/actions/promocodeActions';
import { addUserLogDateApi } from '../api/logDataApi';

const NewPromocodesContainer = () => {

    const dispatch = useDispatch()
    const newPromocodesList = useSelector(promocodeListSelector)
    const userData = useSelector(userDataSelector)


    useEffect(() => {
        dispatch(loadManagerPromocodeListAction(userData))
        addUserLogDateApi(userData, '/newPromocodes')
    }, [])

    return (
            <EditTablePromocodeList
                items={newPromocodesList}
                userData={userData}
                status={NEW_PROMOCODE_STATUS}
            />
    )
}

export default NewPromocodesContainer
