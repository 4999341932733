import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ViewTableManagersTotalSale from '../views/ViewTableManagersTotalSale';
import { loadStartAction } from './../data/actions/loadStartActions';
import { managerItemSalesTotalSumSelector, managerSalesTableSelector } from '../data/selectors/managerListSelector';
import { getManagerSaleListAction } from '../data/actions/managerListActions';
import { userDataSelector } from './../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';
import { getProductQtyListAction } from '../data/actions/productQtyListActions';
import { productQtyListTableMonthSelector } from '../data/selectors/productQtySelector';


const DashboardManagerSalesContainer = () => {

    const userData = useSelector(userDataSelector)

    const yearData = userData.settingsData.yearData
    const managerId = userData.managerId

    const [year, setYear] = useState(yearData)

    const dispatch = useDispatch()

    const productQtyListByMonth = useSelector(productQtyListTableMonthSelector)
    const managerItemSalesTotalSum = useSelector(managerItemSalesTotalSumSelector(productQtyListByMonth))

    const data = { userData, year, month: 0, managerIdData: managerId }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(getManagerSaleListAction(userData, year))
        addUserLogDateApi(userData, '/')
    }, [year])

    useEffect(() => {
        dispatch(getProductQtyListAction(data))
    }, [year])

    return (
        // <div></div>
        <ViewTableManagersTotalSale
            items={managerItemSalesTotalSum}
            year={year}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default DashboardManagerSalesContainer
