import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditTableDoctorsBonusList from '../views/EditTableDoctorsBonusList';
import { loadStartAction } from '../data/actions/loadStartActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { ACTIVE_PROMOCODE_STATUS } from '../data/constants/statuses';
import { activatedPromocodeBonusListSelector, newPromocodeBonusListSelector } from '../data/selectors/promocodeSelector';
import ViewTableDoctorsBonusList from './../views/ViewTableDoctorsBonusList';
import { loadManagerPromocodeBonusList, loadManagerPromocodeBonusListAction } from '../data/actions/promocodeActions';
import { addUserLogDateApi } from '../api/logDataApi';

const ReportDoctorsBonusListContainer = () => {

    const dispatch = useDispatch()

    const archiveStatus = true


    const activatedPromocodeBonusList = useSelector(activatedPromocodeBonusListSelector(archiveStatus))
    const userData = useSelector(userDataSelector)

    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData

    const [filterMonth, setFilterMonth] = useState(currentMonth)
    const [filterYear, setFilterYear] = useState(currentYear)

    // const testRes = useSelector(testSelector)

    const handleChangeMonth = (event) => {
        setFilterMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setFilterYear(event.target.value)
    }

    useEffect(() => {
        dispatch(loadManagerPromocodeBonusListAction(userData, filterMonth, filterYear))
        addUserLogDateApi(userData, '/bonusReport')
    }, [filterMonth, filterYear])

    return (
        <ViewTableDoctorsBonusList
            items={activatedPromocodeBonusList}
            userData={userData}
            month={filterMonth}
            year={filterYear}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default ReportDoctorsBonusListContainer
