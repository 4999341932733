import history from '../../history';
import { LOGIN_SUCCESS } from '../reducers/authenticationReducer';
import { loginApi } from './../../auth/loginApi';

export const loginAction = (username, password) => {
    return async (dispatch) => {
        const res = await loginApi(username, password)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res,
        })
        history.push('/');
    }
}