import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { csvToJsonDebetListHelper, isDataMatching } from "./../data/helpers/csvToJsonDataHelpers";

const CsvUploaderDebet = ({ apiUrl, existingItems, token }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const fieldsToCompare = ['itemId'];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
      },
    });
  };

  const isEmptyData = (data) => {
    return (
      !data.itemId &&
      !data.month &&
      !data.year &&
      !data.contragent &&
      (!data.manager || !data.manager.id) &&
      !data.realization &&
      !data.payed &&
      !data.balanceToPay &&
      !data.debtPrevMonth &&
      !data.totalDebt
    );
  };


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      let isDataUpdated = false;
      for (const csvItem of csvData) {
        const resItem = csvToJsonDebetListHelper(csvItem, "debets");

        if (isEmptyData(resItem)) {
          console.log("Skipping empty data");
          continue;
        }

        const existingItem = existingItems.find((item) => {
          return isDataMatching(item, resItem, fieldsToCompare);
        });

        if (existingItem) {
          const updatedData = {
            totalDebt: resItem.totalDebt,
            realization: resItem.realization,
            payed: resItem.payed,
            balanceToPay: resItem.balanceToPay,
            debtPrevMonth: resItem.debtPrevMonth,
          };

          if (isEqual(existingItem, { ...existingItem, ...updatedData })) {
            console.log("Item data is fully matched. Skipping...");
          } else {
            console.log(`Existing debet item id: ${existingItem.id}`);

            await axios.put(`${apiUrl}/${existingItem.id}`, updatedData, {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: `Bearer ${token}`,
              },
            });

            console.log(`Item debet with id ${existingItem.id} updated`);
            isDataUpdated = true;
          }
        } else {
          await axios.post(apiUrl, resItem, {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: `Bearer ${token}`,
            },
          });

          console.log("New debet item created");
          isDataUpdated = true;
        }
      }

      if (isDataUpdated) {
        alert("Данные успешно загружены");
      } else {
        alert("Данные в CSV-файле полностью совпадают с существующими данными");
      }
    } catch (error) {
      console.error(error);
      alert("Ошибка загрузки данных на сервер");
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="file-upload">Выберите CSV-файл для загрузки (8.6.23.11:19):</label>
        <input
          id="file-upload"
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
        />
        <button type="submit" disabled={!selectedFile}>
          Загрузить и отправить данные
        </button>
      </form>
    </div>
  );
};

CsvUploaderDebet.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  existingItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string.isRequired,
};

export default CsvUploaderDebet;