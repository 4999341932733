import axios from 'axios';
import { BACKEND_DOMAIN } from "../data/constants/statuses";

export const getPromocodeCommentItemApi = async (userData, promocode) => {
  // console.log("🚀 ~ getPromocodeCommentItemApi ~ promocode:", promocode)
  const { token, managerId } = userData
  const res = axios
    .get(`${BACKEND_DOMAIN}/promocodes-comments?_limit=-1&promocode=${promocode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        return response.data
      });
  return res;
}

export const createPromocodeCommentItemApi = async (userData, data) => {
  const { token } = userData

  const res = axios
    .post(`${BACKEND_DOMAIN}/promocodes-comments/`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      return response
    });
  return res;
}