import './App.css';
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store';
import history from './history';
import LoginFormContainer from './containers/LoginFormContainer';
import { PrivateRoute } from './components/PrivateRoute';
import DashboardPage from './pages/DashboardPage';
import NewPromocodesPage from './pages/NewPromocodesPage';
import DoctorsBonusPage from './pages/DoctorsBonusPage';
import DoctorsBonusReportPage from './pages/DoctorsBonusReportPage';
import UpdateDataContainer from './containers/UpdateDataContainer';
import DashboardAdminPage from './pages/DashboardAdminPage';
import OrderListReportPage from './pages/OrderListReportPage';
import AdminOrderListReportPage from './pages/AdminOrderListReportPage';
import AdminDoctorsBonusReportPage from './pages/AdminDoctorsBonusReportPage';
import AdminAllPromocodesPage from './pages/AdminAllPromocodesPage';
import AdminProductQtyListReportPage from './pages/AdminProductQtyListReportPage';
import AdminDebetListReportPage from './pages/AdminDebetListReportPage';
import ProductQtyListReportPage from './pages/ProductQtyListReportPage';
import DebetListReportPage from './pages/DebetListReportPage';
import CalcFormPage from './pages/CalcFormPage';
import AdminCalcFormPage from './pages/AdminCalcFormPage';
import BonusSystemPage from './pages/BonusSystemPage';
import BonusSystemItemPage from './pages/BonusSystemItemPage';
import AdminBonusSystemPage from './pages/AdminBonusSystemPage';
import AdminBonusSystemItemPage from './pages/AdminBonusSystemItemPage';
import AdminBonusSystemPaymentsPage from './pages/AdminBonusSystemPaymentsPage';

function App() {

  return (
    <Router history={history}>
      <Switch>
        <Provider store={store}>
          <PrivateRoute exact path='/' component={DashboardPage} />
          <Route path='/login' component={LoginFormContainer} />
          <Route path='/newPromocodes' component={NewPromocodesPage} />
          <Route path='/bonusForm' component={DoctorsBonusPage} />
          <Route path='/bonusReport' component={DoctorsBonusReportPage} />
          <Route path='/orderListReport' component={OrderListReportPage} />
          <Route path='/productQtyListReport' component={ProductQtyListReportPage} />
          <Route path='/debetListReport' component={DebetListReportPage} />
          <Route path='/orderForm' component={CalcFormPage} />
          <Route path='/orderFormFeetcalm' component={CalcFormPage} />
          <Route path='/update-data' component={UpdateDataContainer} />
          <Route path='/bonusSystem' component={BonusSystemPage} />
          <Route path='/bonusSystemItem' component={BonusSystemItemPage} />
          <Route path='/admin/dashboard' component={DashboardAdminPage} />
          <Route path='/admin/allPromocodes' component={AdminAllPromocodesPage} />
          <Route path='/admin/doctorsBonusReport' component={AdminDoctorsBonusReportPage} />
          <Route path='/admin/orderListReport' component={AdminOrderListReportPage} />
          <Route path='/admin/productQtyListReport' component={AdminProductQtyListReportPage} />
          <Route path='/admin/debetListReport' component={AdminDebetListReportPage} />
          <Route path='/admin/orderForm' component={AdminCalcFormPage} />
          <Route path='/admin/orderFormFeetcalm' component={AdminCalcFormPage} />
          <Route path='/admin/bonusSystem' component={AdminBonusSystemPage} />
          <Route path='/admin/bonusSystemPayments' component={AdminBonusSystemPaymentsPage} />
          <Route path='/admin/bonusSystemItem' component={AdminBonusSystemItemPage} />
          {/* <Redirect from="*" to="/" /> */}
        </Provider>
      </Switch>
    </Router>
  );
}

export default App;
