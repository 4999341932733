// RegionRow.js

import React from 'react';
import RegionRowItem from './RegionRowItem';

const RegionRow = ({ tableData, userData, showPreviousMonths }) => {

  return (
    tableData.map((row, index) => {
      return <RegionRowItem
        key={row.id}
        item={row}
        userData={userData}
        showPreviousMonths={showPreviousMonths}
      />
    })
  )
};

export default RegionRow;