import axios from 'axios';
import { BACKEND_DOMAIN, LOCATION } from './../data/constants/statuses';
import { numberOfMonths } from '../data/constants/dates';

export const getBonusDataForPeriod = async (userData, year, month, selectedManagerId, promocodeFilter) => {
  const { token, managerIdList } = userData;
  const promises = [];

  const managerId = selectedManagerId;
  const currentDate = new Date(year, month - 1);
  for (let i = 0; i < numberOfMonths; i++) {
    const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth() + 1;
    const promocode = promocodeFilter ? `&promocode=${promocodeFilter}` : '';

    const promise = axios.get(
      `${BACKEND_DOMAIN}/promocodes-bonuses?_limit=-1&manager=${managerId}&bonusMonth=${targetMonth}&bonusYear=${targetYear}${promocode}&brand=ELEGANT`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(response => response.data);

    promises.push(promise);
  }

  // Wait for all promises to resolve
  const results = await Promise.all(promises);

  // Concatenate all results into a single array
  const combinedResult = results.reduce((acc, result) => acc.concat(result), []);

  return combinedResult;
};

export const getOrderDataForPeriod = async (userData, year, month, selectedManagerId, promocodeFilter) => {
  const { token, managerIdList } = userData;
  const promises = [];

  const managerId = selectedManagerId;
  const currentDate = new Date(year, month - 1);
  for (let i = 0; i < numberOfMonths; i++) {
    const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth() + 1;
    const promocode = promocodeFilter ? `&promocode=${promocodeFilter}` : '';

    const promise = axios.get(
      `${BACKEND_DOMAIN}/orders?_limit=-1&manager=${managerId}&orderYear=${targetYear}&orderMonth=${targetMonth}${promocode}&brand=ELEGANT`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(response => response.data);

    promises.push(promise);
  }

  // Wait for all promises to resolve
  const results = await Promise.all(promises);

  // Concatenate all results into a single array
  const combinedResult = results.reduce((acc, result) => acc.concat(result), []);

  return combinedResult;
};

export const getPromocodeData = async (userData) => {
  const { token, managerIdList } = userData;
  const promises = [];

  managerIdList.forEach(manager => {
    const managerId = manager.id;

    const promise = axios.get(
      `${BACKEND_DOMAIN}/promocodes?_limit=-1&_locale=${LOCATION}&manager=${managerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(response => response.data);

    promises.push(promise);
  });

  // Wait for all promises to resolve
  const results = await Promise.all(promises);

  // Concatenate all results into a single array
  const combinedResult = results.reduce((acc, result) => acc.concat(result), []);

  return combinedResult;
};