import { createSelector } from 'reselect';
import { promocodeBonusListSelector, promocodeListSelector } from './promocodeSelector';
import { orderListItemsSelector } from './orderListItemsSelector';
import { uniq } from 'lodash';
import { numberOfMonths } from '../constants/dates';

export const getTotalBonusSumsByYearAndMonth = (year, month, manager) => createSelector(
  [promocodeBonusListSelector],
  (bonusData) => {
    // Фильтруем данные бонусов по указанному году и месяцу
    const filteredData = bonusData.filter(item => item.bonusYear === year && item.bonusMonth === month && item.manager.id === manager);

    // Считаем суммы по ключам ordersSum и bonusSum
    const promocodeOrdersSum = filteredData.reduce((total, item) => total + item.ordersSum, 0);
    const bonusSum = filteredData.reduce((total, item) => total + item.bonusSum, 0);
    const bonusRecommendedSum = filteredData.reduce((total, item) => total + item.bonusRecommended, 0);

    return { promocodeOrdersSum, bonusSum, bonusRecommendedSum };
  }
);


export const getBonusDataByByYearAndMonthAndRegion = (year, month, region, manager) => createSelector(
  [promocodeBonusListSelector],
  (bonusData) => {
    const managerBonusList = bonusData && bonusData.filter(item => item.manager.id === manager);
    const uniqueRegionList = [...new Set(managerBonusList
      .filter(item => item.bonusYear === year && item.bonusMonth === month && item.region !== "")
      .map(item => item.region))]
      .sort((a, b) => (a && b) ? a.localeCompare(b) : 0);

    const firstRegion = uniqueRegionList[0];
    const filteredItems = managerBonusList.filter(item => item.bonusYear === year && item.bonusMonth === month && item.region === region && item.manager.id === manager);

    // Формируем массив ключей год-месяц для последних трех месяцев
    const previousMonths = [];
    for (let i = 1; i < numberOfMonths; i++) {
      let targetMonth = month - i; // Теперь месяцы индексируются с 1 до 12
      let targetYear = year;
      if (targetMonth < 1) {
        targetMonth += 12;
        targetYear -= 1;
      }
      previousMonths.push(`${targetYear}_${targetMonth < 10 ? '0' : ''}${targetMonth}`);
    }

    // Добавляем ключи и значения для каждого предыдущего месяца
    filteredItems.forEach(item => {
      item.previousData = previousMonths.map(yearMonth => {
        const bonusIddKey = `${yearMonth}_id`;
        const bonusPromocodeKey = `${yearMonth}_promocode`;
        const bonusRecommendedKey = `${yearMonth}_BonusRecommended`;
        const bonusSumKey = `${yearMonth}_BonusSum`;
        const appointmentsKey = `${yearMonth}_Appointments`;
        const toPayKey = `${yearMonth}_toPay`;
        const isPayedKey = `${yearMonth}_isPayed`;
        const matchingBonus = managerBonusList.find(bonus =>
          bonus.promocode === item.promocode &&
          `${bonus.bonusYear}_${bonus.bonusMonth < 10 ? '0' : ''}${bonus.bonusMonth}` === yearMonth
        );
        return {
          [bonusIddKey]: matchingBonus ? matchingBonus.id : null,
          [bonusPromocodeKey]: matchingBonus ? matchingBonus.promocode : null,
          [bonusRecommendedKey]: matchingBonus ? matchingBonus.bonusRecommended : null,
          [bonusSumKey]: matchingBonus ? matchingBonus.bonusSum : null,
          [appointmentsKey]: matchingBonus ? matchingBonus.appointments : null,
          [toPayKey]: matchingBonus ? matchingBonus.toPay : null,
          [isPayedKey]: matchingBonus ? matchingBonus.isPayed : null,
        };
      });
    });

    const filteredPromocodeOrdersSum = filteredItems.reduce((total, item) => total + item.ordersSum, 0);
    const filteredBonusSum = filteredItems.reduce((total, item) => total + item.bonusSum, 0);

    return { filteredItems, uniqueRegionList, firstRegion, filteredPromocodeOrdersSum, filteredBonusSum, previousMonths };
  }
);

export const getTotalOrderSumsByYearAndMonth = (year, month, manager) => createSelector(
  [orderListItemsSelector],
  (orderData) => {
    const filteredData = orderData.filter(item => item.orderYear === year && item.orderMonth === month && item.promocode === "no orderPromocode" && item.manager.id === manager);

    const greyZoneOrdersSum = filteredData.reduce((total, item) => total + item.productTotalPrice, 0);

    return greyZoneOrdersSum;
  }
);

export const getOrderDataByYearAndMonthAndRegion = (year, month, region, manager) => createSelector(
  [orderListItemsSelector],
  (orderData) => {
    const filteredData = orderData.filter(item => item.orderYear === year && item.orderMonth === month && item.promocode === "no orderPromocode" && item.region === region && item.manager.id === manager);

    const greyZoneOrdersSumByRegion = filteredData.reduce((total, item) => total + item.productTotalPrice, 0);

    return { greyZoneOrdersSumByRegion };
  }
);

export const getTotalSumsByYearAndMonth = (manager) => createSelector(
  [orderListItemsSelector],
  (orderData) => {
    const sumsByYearAndMonth = orderData.filter(i => i.manager.id === manager).reduce((acc, item) => {
      const key = `${item.orderYear}_${String(item.orderMonth).padStart(2, '0')}`;
      acc[key] = (acc[key] || 0) + item.productTotalPrice;
      return acc;
    }, {});

    const resultArray = Object.entries(sumsByYearAndMonth).map(([key, sum]) => ({
      yearMonth: key,
      orderSum: sum,
    }));

    // Сортировка массива по году и месяцу в убывающем порядке
    resultArray.sort((a, b) => {
      const [bYear, bMonth] = b.yearMonth.split('_').map(Number);
      const [aYear, aMonth] = a.yearMonth.split('_').map(Number);
      return bYear - aYear || bMonth - aMonth;
    });

    return resultArray;
  }
);

// bonusItem module

export const getBonusDataByFilter = (year, month, promocode) => createSelector(
  [promocodeBonusListSelector],
  (bonusData) => {

    const selectedBonusItem = promocode !== '' ? bonusData.find(item => item.bonusYear === year && item.bonusMonth === month && item.promocode === promocode) : null;

    // Формируем массив ключей год-месяц для последних трех месяцев
    const previousMonths = [];
    for (let i = 1; i < numberOfMonths; i++) {
      let targetMonth = month - i; // Теперь месяцы индексируются с 1 до 12
      let targetYear = year;
      if (targetMonth < 1) {
        targetMonth += 12;
        targetYear -= 1;
      }
      previousMonths.push(`${targetYear}_${targetMonth < 10 ? '0' : ''}${targetMonth}`);
    }

    // Добавляем ключи и значения для каждого предыдущего месяца
    if (selectedBonusItem) {
      selectedBonusItem.previousData = previousMonths.map(yearMonth => {
        const bonusRecommendedKey = `${yearMonth}_BonusRecommended`;
        const bonusSumKey = `${yearMonth}_BonusSum`;
        const appointmentsKey = `${yearMonth}_Appointments`;
        const toPaySumKey = `${yearMonth}_toPaySum`;
        const isPayedKey = `${yearMonth}_isPayed`;
        const matchingBonus = bonusData.find(bonus =>
          bonus.promocode === selectedBonusItem.promocode &&
          `${bonus.bonusYear}_${bonus.bonusMonth < 10 ? '0' : ''}${bonus.bonusMonth}` === yearMonth
        );
        return {
          [bonusRecommendedKey]: matchingBonus ? matchingBonus.bonusRecommended : null,
          [bonusSumKey]: matchingBonus ? matchingBonus.bonusSum : null,
          [appointmentsKey]: matchingBonus ? matchingBonus.appointments : null,
          [isPayedKey]: matchingBonus ? matchingBonus.isPayed : null,
          [toPaySumKey]: matchingBonus ? (matchingBonus.isPayed ? matchingBonus.toPaySum : 0) : null,
        };
      });
    }

    return { selectedBonusItem, previousMonths };
  }
);

export const getPromocodeDataByDoctor = (doctorName, promocode) => createSelector(
  [promocodeListSelector],
  (promocodeData) => {

    // Фильтруем данные по частичному совпадению имени врача
    const filteredItems = promocodeData

    const selectedItem = doctorName !== '' ? filteredItems.find(item => item.doctor === doctorName && item.promocode === promocode) : filteredItems.find(item => item.promocode === promocode);

    return { filteredItems, selectedItem };
  }
);

export const getOrdersDataByFilter = (year, month, promocode) => createSelector(
  [orderListItemsSelector],
  (orderData) => {

    const selectedData = orderData.filter(item => item.orderYear === year && item.orderMonth === month && item.promocode === promocode);

    // Формируем массив ключей год-месяц для последних трех месяцев
    const previousMonths = [];
    for (let i = 1; i < numberOfMonths; i++) {
      let targetMonth = month - i; // Теперь месяцы индексируются с 1 до 12
      let targetYear = year;
      if (targetMonth < 1) {
        targetMonth += 12;
        targetYear -= 1;
      }
      previousMonths.push(`${targetYear}_${targetMonth < 10 ? '0' : ''}${targetMonth}`);
    }

    // Добавляем ключи и значения для каждого предыдущего месяца
    if (selectedData) {
      selectedData.previousData = previousMonths.map(yearMonth => {
        const productTotalPriceSumKey = `${yearMonth}_productTotalPriceSum`;
        const productQuantitySumKey = `${yearMonth}_productQuantitySum`;
        const productsByQuantitiesKey = `${yearMonth}_productsByQuantities`;
        const matchingOrders = orderData.filter(order =>
          order.promocode === promocode &&
          `${order.orderYear}_${order.orderMonth < 10 ? '0' : ''}${order.orderMonth}` === yearMonth
        );

        const productTotalPriceSum = matchingOrders.reduce((sum, order) => sum + order.productTotalPrice, 0);
        const productQuantitySum = matchingOrders.reduce((sum, order) => sum + order.productQuantity, 0);

        const productsByQuantities = matchingOrders.reduce((total, order) => {
          if (!total[order.productArticle]) {
            total[order.productArticle] = 0;
          }
          total[order.productArticle] += order.productQuantity;
          return total;
        }, {});

        return {
          [productTotalPriceSumKey]: productTotalPriceSum,
          [productQuantitySumKey]: productQuantitySum,
          [productsByQuantitiesKey]: productsByQuantities,
        };
      });
    }


    const saleQtySum = selectedData.reduce((total, item) => total + item.productQuantity, 0);
    const salePriceSum = selectedData.reduce((total, item) => total + item.productTotalPrice, 0);

    const productsByQuantity = selectedData.reduce((total, item) => {
      if (!total[item.productArticle]) {
        total[item.productArticle] = 0;
      }
      total[item.productArticle] += item.productQuantity;
      return total;
    }, {});

    return { selectedData, saleQtySum, salePriceSum, productsByQuantity };
  }
);

export const getBonusPaymentsDataByByYearAndMonth = (year, month, manager) => createSelector(
  [promocodeBonusListSelector],
  (bonusData) => {
    const managerBonusList = bonusData && bonusData.filter(item => item.manager.id === manager);
    const filteredItems = managerBonusList.filter(item => item.bonusYear === year && item.bonusMonth === month && item.manager.id === manager && item.toPay);

    // Формируем массив ключей год-месяц для последних трех месяцев
    const previousMonths = [];
    for (let i = 1; i < numberOfMonths; i++) {
      let targetMonth = month - i; // Теперь месяцы индексируются с 1 до 12
      let targetYear = year;
      if (targetMonth < 1) {
        targetMonth += 12;
        targetYear -= 1;
      }
      previousMonths.push(`${targetYear}_${targetMonth < 10 ? '0' : ''}${targetMonth}`);
    }

    // Добавляем ключи и значения для каждого предыдущего месяца
    filteredItems.forEach(item => {
      item.previousData = previousMonths.map(yearMonth => {
        const bonusIddKey = `${yearMonth}_id`;
        const isPayedKey = `${yearMonth}_isPayed`;
        const matchingBonus = managerBonusList.find(bonus =>
          bonus.promocode === item.promocode &&
          `${bonus.bonusYear}_${bonus.bonusMonth < 10 ? '0' : ''}${bonus.bonusMonth}` === yearMonth
        );
        return {
          [bonusIddKey]: matchingBonus ? matchingBonus.id : null,
          [isPayedKey]: matchingBonus ? matchingBonus.isPayed : null,
        };
      });
    });

    return { filteredItems };
  }
);