import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { loginAction } from './../data/actions/loginAction';
import { Link, useLocation } from 'react-router-dom';
import { loadStartAction } from './../data/actions/loadStartActions';
import LoginForm from './../views/LoginForm';

const LoginFormContainer = () => {

    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;

    const dispatch = useDispatch()
    const location = useLocation();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        setSubmitted(true);
        if (username && password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            dispatch(loginAction(username, password));
        }
        console.log({
            email: data.get('username'),
            password: data.get('password'),
        });
    };

    // useEffect(() => {
    //     dispatch(loadStartAction())
    // })
    return (
        <LoginForm handleChange={handleChange} handleSubmit={handleSubmit} />
    )
}

export default LoginFormContainer
