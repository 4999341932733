import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import RegionRow from './RegionRow';
import { monthNameList } from '../../data/constants/dates';
import BonusPaymentRow from './BonusPaymentRow';

const DataPaymentsTable = ({ tableData, regionFilter, userData, yearFilter, monthFilter, greyZoneOrdersSumByRegion, filteredPromocodeOrdersSum, filteredBonusSum, previousMonths, showPreviousMonths }) => {
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortField, setSortField] = useState('region');

  const handleSort = (field) => {
    setSortField(field);
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
  };

  const sortNumbers = (a, b) => {
    if (a == null) return 1;
    if (b == null) return -1;
    return sortDirection === 'asc' ? a - b : b - a;
  };

  const sortStrings = (a, b) => {
    const valueA = (a || '').toLowerCase();
    const valueB = (b || '').toLowerCase();
    if (!valueA) return 1;
    if (!valueB) return -1;
    if (valueA < valueB) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  };

  const sortedData = [...tableData].sort((a, b) => {
    const valueA = a[sortField];
    const valueB = b[sortField];
    return typeof valueA === 'number' ? sortNumbers(valueA, valueB) : sortStrings(valueA, valueB);
  });

  return (
    <TableContainer style={{ overflow: 'visible', paddingTop: 131 }}>
      <Table style={{ overflow: 'visible' }}>
        <TableHead style={{ backgroundColor: '#fff', position: 'sticky', top: 132, zIndex: 10 }}>
          <TableRow>
            <TableCell>
            <TableSortLabel
                active={sortField === 'region'}
                direction={sortDirection}
                onClick={() => handleSort('region')}
              >
                <strong>Область</strong>
              </TableSortLabel>
              </TableCell>
            <TableCell><strong>Місто</strong></TableCell>
            <TableCell><strong>Промокод</strong></TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === 'doctor'}
                direction={sortDirection}
                onClick={() => handleSort('doctor')}
              >
                <strong>ПІБ</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === 'doctorCategory'}
                direction={sortDirection}
                onClick={() => handleSort('doctorCategory')}
              >
                <strong>Категорія</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell><strong>Номер телефону</strong></TableCell>
            <TableCell><strong>До оплати<br />{sortedData.reduce((sum, item) => sum + item.toPaySum, 0)}</strong></TableCell>
            <TableCell><strong>Спосіб бонусації</strong></TableCell>
            <TableCell><strong>Місце доставки</strong></TableCell>
            <TableCell><strong>Оплачено<br />{sortedData.reduce((sum, item) => sum + (item.isPayed ? item.toPaySum : 0), 0)}</strong></TableCell>
            {/* <TableCell><strong>Платіжка</strong></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <BonusPaymentRow
            tableData={sortedData}
            userData={userData}
            showPreviousMonths={showPreviousMonths}
          />
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default DataPaymentsTable;