import SnackbarComponent from "../components/SnackbarComponent";
import HeaderLayout from "../layouts/HeaderLayout";
import DoctorsBonusListContainer from './../containers/DoctorsBonusListContainer';
import { Container } from '@material-ui/core';

const DoctorsBonusPage = () => {

    return (
        <>
            <SnackbarComponent />
            <HeaderLayout />
            {/* <Container> */}
            <DoctorsBonusListContainer />
            {/* </Container> */}
        </>
    )
}

export default DoctorsBonusPage