export const PROMOCODE_COMMENT_ITEM_LOAD_SUCCESSFUL = 'PROMOCODE_COMMENT_ITEM_LOAD_SUCCESSFUL'
export const PROMOCODE_COMMENT_ITEM_CREATE_SUCCESSFUL = 'PROMOCODE_COMMENT_ITEM_CREATE_SUCCESSFUL'

const initialState = {
  items: [],
}

export const promocodeCommentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PROMOCODE_COMMENT_ITEM_LOAD_SUCCESSFUL:
      return { ...state, items: payload }
    case PROMOCODE_COMMENT_ITEM_CREATE_SUCCESSFUL:
      return { ...state, items: [...state.items, payload] }
    default:
      return state
  }
}

export default promocodeCommentsReducer
