import React from 'react';
import { Box, FormControl, MenuItem, Paper, TextField, Typography } from '@material-ui/core';

const UniversalSelect = ({ item, label, field, type, handleSelectChange, disabled, style, menuItems, isRequired }) => {
  const itemId = item.id;
  return (
    <Box>
      <FormControl variant="outlined" margin="dense" style={{ ...style, width: '100%' }}>
        <Typography variant="h8" style={{ textAlign: 'left' }}>
          {label}
        </Typography>
        <TextField
          margin="dense"
          type={type}
          placeholder="Виберіть..."
          value={item[field]}
          onChange={(e) => handleSelectChange(itemId, field, e)}
          disabled={disabled}
          select
          required={isRequired}
        >
          {menuItems.map(menuItem => (
            <MenuItem key={menuItem} value={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Box>
  );
};

export default UniversalSelect;