import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { monthNameList } from '../../data/constants/dates';

const useStyles = makeStyles({
  input: {
    padding: '10.5px 10px',
    width: '120px',
  },
});

const SummaryPanel = ({ totalSalesSum, totalPromocodeSum, totalPromocodeBonus, totalGreyZoneSum, totalGreyZoneBonus, totalBonusLimit, usedBonuses, totalSumsByYearAndMonth }) => {
  const classes = useStyles();
  let salesGrowthPercentage = 0;
  const prevMonthTotalSum = totalSumsByYearAndMonth[1]?.orderSum || 0;
  if (prevMonthTotalSum !== 0) {
    salesGrowthPercentage = Math.floor((totalSalesSum / prevMonthTotalSum - 1) * 100);
  }
  return (
    <div style={{ display: 'flex', backgroundColor: '#ececec', minWidth: '1400px', width: '100%' }}>
      <TextField
        label="Сума всіх продаж"
        variant="outlined"
        margin="dense"
        value={totalSalesSum.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Сума промокоди"
        variant="outlined"
        margin="dense"
        value={totalPromocodeSum.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Бонус промокоди"
        variant="outlined"
        margin="dense"
        value={totalPromocodeBonus.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Сума сіра зона"
        variant="outlined"
        margin="dense"
        value={totalGreyZoneSum.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Бонус сіра зона"
        variant="outlined"
        margin="dense"
        value={totalGreyZoneBonus.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Фінліміт"
        variant="outlined"
        margin="dense"
        style={{ width: 100 }}
        value={totalBonusLimit.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Залишок по бонусам"
        variant="outlined"
        margin="dense"
        style={{ width: 160}}
        value={usedBonuses.toLocaleString('ru-RU')}
        disabled
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      <TextField
        label="Приріст, %"
        variant="outlined"
        margin="dense"
        value={salesGrowthPercentage.toLocaleString('ru-RU')}
        disabled
        style={{ width: 80, marginRight: '10px' }}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
      />
      {totalSumsByYearAndMonth.map((item, index) => {
        if (index === 0) return null;
        const totalSaleSum = item.orderSum;

        const month = item.yearMonth.slice(-2);

              const monthName = (monthNameList.find(m => m.month === month)?.name || month).toLowerCase();
        return (
          <TextField
            label={`Продажі за ${monthName}`}
            variant="outlined"
            margin="dense"
            style={{ width: 160}}
            value={totalSaleSum.toLocaleString('ru-RU')}
            disabled
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
          />
        )
      })}
    </div>
  );
};

export default SummaryPanel;
