import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { adminOrdersDataSelector } from './../data/selectors/orderListItemsSelector';
import ViewTableOrderList from './../views/ViewTableOrderList';
import { loadMonthOrderListAction } from './../data/actions/orderListActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const OrderListPerMonthContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const { managerId } = userData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const { items, ordersSum } = useSelector(adminOrdersDataSelector(managerId));

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(loadMonthOrderListAction(userData, year, month))
        addUserLogDateApi(userData, '/orderListReport')
    }, [month, year])

    return (
        <ViewTableOrderList
            items={items}
            month={month}
            year={year}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
        />
    )
}

export default OrderListPerMonthContainer
