import HeaderLayout from "../layouts/HeaderLayout";
import DebetListReportContainer from "../containers/DebetListReportContainer";

const DebetListReportPage = () => {

    return (
        <>
            <HeaderLayout />
            <DebetListReportContainer />
        </>
    )
}

export default DebetListReportPage