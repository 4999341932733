
import { SET_SNACKBAR } from './../reducers/snackbarReducer';

export const setSnackbarAction = (snackbarOpen, snackbarType = "success", snackbarMessage = "") => {
    return async (dispatch) => {
        dispatch({
            type: SET_SNACKBAR,
            snackbarOpen,
            snackbarType,
            snackbarMessage
        })
    }
}