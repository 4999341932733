import { getProductQtyListApi, updateProductQtyByIdApi } from "../../api/productQtyListApi"
import { PRODUCT_QTY_LIST_LOAD_SUCCESSFUL } from './../reducers/productQtyListReducer';
import { setSnackbarAction } from './snackbarActions';


export const getProductQtyListAction = (data) => {
    return async (dispatch) => {
        const res = await getProductQtyListApi(data)
        dispatch({
            type: PRODUCT_QTY_LIST_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}

export const updateProductQtyAction = (data) => {
    return async (dispatch) => {
        const res = await updateProductQtyByIdApi(data)
        if (res !== 200) return dispatch(setSnackbarAction(true, "error", "Помилка! Дані не збережені."))
        if (res === 200) {
            dispatch(getProductQtyListAction(data))
            return dispatch(setSnackbarAction(true, "success", "Дані збережені."))
        }
    }
}