import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import RegionRow from './RegionRow';
import { monthNameList } from '../../data/constants/dates';

const DataTable = ({ tableData, regionFilter, userData, yearFilter, monthFilter, greyZoneOrdersSumByRegion, filteredPromocodeOrdersSum, filteredBonusSum, previousMonths, showPreviousMonths }) => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('bonusSum');

  const handleSort = (field) => {
    setSortField(field);
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
  };

  const sortNumbers = (a, b) => {
    if (a == null) return 1;
    if (b == null) return -1;
    return sortDirection === 'asc' ? a - b : b - a;
  };

  const sortStrings = (a, b) => {
    const valueA = (a || '').toLowerCase();
    const valueB = (b || '').toLowerCase();
    if (!valueA) return 1;
    if (!valueB) return -1;
    if (valueA < valueB) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  };

  const sortedData = [...tableData].sort((a, b) => {
    const valueA = a[sortField];
    const valueB = b[sortField];
    return typeof valueA === 'number' ? sortNumbers(valueA, valueB) : sortStrings(valueA, valueB);
  });

  return (
    <TableContainer style={{ overflow: 'visible', paddingTop: 181 }}>
      <Table style={{ overflow: 'visible' }}>
        <TableHead style={{ backgroundColor: '#fff', position: 'sticky', top: 182, zIndex: 10 }}>
          <TableRow>
            <TableCell><strong>На оплату</strong></TableCell>
            <TableCell><strong>Область<br />{regionFilter}</strong></TableCell>
            <TableCell><strong>Місто</strong></TableCell>
            <TableCell><strong>Промокод</strong></TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === 'doctor'}
                direction={sortDirection}
                onClick={() => handleSort('doctor')}
              >
                <strong>ПІБ</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === 'doctorCategory'}
                direction={sortDirection}
                onClick={() => handleSort('doctorCategory')}
              >
                <strong>Кате<br/>горія</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell><strong>Спосіб<br/>бонусації</strong></TableCell>
            <TableCell><strong>До оплати<br />{sortedData.reduce((sum, item) => sum + (item.toPay ? item.toPaySum : 0), 0)}</strong></TableCell>
            <TableCell style={{ minWidth: '80px' }}><strong>Реком. бонус<br />{sortedData.reduce((sum, item) => sum + item.bonusRecommended, 0)}</strong></TableCell>
            <TableCell ><strong>Продажі промокод<br />{filteredPromocodeOrdersSum}</strong></TableCell>
            <TableCell style={{ width: '114px' }}>
              <TableSortLabel
                active={sortField === 'bonusSum'}
                direction={sortDirection}
                onClick={() => handleSort('bonusSum')}
              >
                <strong>Бонус за промокодом<br />{filteredBonusSum}</strong>
              </TableSortLabel>
            </TableCell>
            <TableCell><strong>Призначення лікарів</strong></TableCell>
            {previousMonths.map((yearMonth, index) => {
              if (index > 0 && !showPreviousMonths) return null;

              const month = yearMonth.slice(-2);

              const monthName = (monthNameList.find(m => m.month === month)?.name || month).toLowerCase();
              return (
                <React.Fragment key={index}>
                  <TableCell style={{ color: '#999' }}>Реком. бонус, {monthName}</TableCell>
                  <TableCell style={{ color: '#999' }}>Бонус за промокодом, {monthName}</TableCell>
                  <TableCell style={{ color: '#999' }}>Призначення лікарів, уп, {monthName}</TableCell>
                </React.Fragment>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <RegionRow
            tableData={sortedData}
            userData={userData}
            showPreviousMonths={showPreviousMonths}
          />
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default DataTable;