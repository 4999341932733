export const DEBET_LIST_LOAD_SUCCESSFUL = 'DEBET_LIST_LOAD_SUCCESSFUL'

const initialState = {
    items: []
}

export const debetsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case DEBET_LIST_LOAD_SUCCESSFUL:
            return { ...state, items: payload }
        default:
            return state
    }
}

export default debetsReducer
