import React from 'react';
import { Box, FormControl, Paper, TextField, Typography } from '@material-ui/core';

const UniversalInput = ({ item, label, field, type, handleInputChange, disabled, style }) => {
  const itemId = item.id;
  return (
    <Box>
      <FormControl variant="outlined" margin="dense" style={{ ...style, width: '100%' }}>
        <Typography variant="h8" style={{ textAlign: 'left' }}>
          {label}
        </Typography>
        <TextField
          type={type}
          placeholder="Введіть дані....."
          value={item[field]}
          onChange={(e) => handleInputChange(itemId, field, e)}
          disabled={disabled}
          style={style}
        />
      </FormControl>
    </Box>
  );
};

export default UniversalInput;