import React from "react";
import { Link, useLocation } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ACTIVE_PROMOCODE_STATUS } from "../data/constants/statuses";
import { NEW_PROMOCODE_STATUS } from './../data/constants/statuses';
import { Button, makeStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  button: {
    margin: theme.spacing(0),
    padding: 2,
    minWidth: 20
  }
}));

const PromocodeReadRow = ({ item, itemId, status, handleEditClick, handleActivateClick }) => {
  const classes = useStyles();
  const location = useLocation();


  return (
    <TableRow key={itemId}>
      {/* <TableCell component="th" scope="row">
        {item.manager.managerName}
      </TableCell> */}
      <TableCell component="th" scope="row" className={classes.tableCell}>
        {item.promocode.startsWith('t_') ? '-' : item.promocode}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Link to={{
          pathname: "/bonusSystemItem",
          state: {
            doctor: item.doctor, promocode: item.promocode, manager: item.manager.id,
            from: location.pathname
          }
        }}
          title='Перейти в карточку спеціаліста'
        >
          {item.doctor}
        </Link>
      </TableCell>
      <TableCell className={classes.tableCell}>{item.clinic}</TableCell>
      <TableCell className={classes.tableCell}>{item.city}</TableCell>
      <TableCell className={classes.tableCell}>{item.region}</TableCell>
      <TableCell className={classes.tableCell}>{item.phone}</TableCell>
      <TableCell className={classes.tableCell}>{item.bonusMethod}</TableCell>
      {!item.activated && <TableCell component="th" scope="row" className={classes.tableCell}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={(event) => handleEditClick(event, item, itemId)}
        >
          <EditIcon />
        </Button>
      </TableCell>
      }
      {!item.activated && <TableCell component="th" scope="row" className={classes.tableCell}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={(event) => handleActivateClick(event, item, itemId)}
        >
          <SendIcon />
        </Button>
      </TableCell>
      }
    </TableRow>
  );
};

export default PromocodeReadRow;