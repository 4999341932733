import SnackbarComponent from "../components/SnackbarComponent";
import CalcFormContainer from "../containers/CalcFormContainer";
import AdminHeaderLayout from "../layouts/AdminHeaderLayout";

const AdminCalcFormPage = () => {

    return (
        <>
            <SnackbarComponent />
            <AdminHeaderLayout />
            <CalcFormContainer />
        </>
    )
}

export default AdminCalcFormPage