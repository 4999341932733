import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, List, ListItem, ListItemText, Divider, Typography, Grid, makeStyles } from '@material-ui/core';
import { createPromocodeCommentItemAction } from '../../data/actions/promocodeCommentsActions';

const useStyles = makeStyles({
  body1: {
    fontSize: '12px',
  },
});

const Comment = ({ userData, promocode, promocodeComments }) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const commentData = {
      promocode,
      comment: comment
    };

    dispatch(createPromocodeCommentItemAction(userData, commentData)); // Добавить действие создания комментария

    setComment(''); // Очистить поле ввода после отправки
  };

  return (
    <Grid container>
      {userData.role === 'Manager1' && <Grid item xs={12} sm={3}>
        <Typography style={{ width: 'fit-content' }}><strong>Коментарі:</strong></Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            value={comment}
            onChange={e => setComment(e.target.value)}
            label="Введіть коментар"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" size='small'>
            Зберегти коментар
          </Button>
        </form>
      </Grid>
      }
      <Divider orientation="vertical" flexItem style={{ margin: '10px' }} />
      <Grid item xs={12} sm={4}>
        {userData.role !== 'Manager1' && <Typography style={{ width: 'fit-content' }}><strong>Коментарі:</strong></Typography>}
        <List style={{ height: 250, overflow: 'auto', padding: '0' }}>
          {promocodeComments && [...promocodeComments].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).map((item) => {
            return (
              <>
                <ListItem style={{ padding: '0' }}>
                  <ListItemText
                    primary={item.comment}
                    secondary={new Date(item.updated_at).toLocaleString()}
                    classes={{ primary: classes.body1, secondary: classes.body1 }}
                  />
                </ListItem>
                <Divider />
              </>
            )
          })}
        </List >
      </Grid>
    </Grid>
  );
};

export default Comment;