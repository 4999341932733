import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { snackbarMessageSelector, snackbarOpenSelector, snackbarTypeSelector } from './../data/selectors/snackbarSelector';
import { setSnackbarAction } from './../data/actions/snackbarActions';

const useStyles = makeStyles({
  success: {
    backgroundColor: '#4caf50',
  },
  error: {
    backgroundColor: '#f44336',
  }
});

const SnackbarComponent = () => {

  const classes = useStyles();

  const dispatch = useDispatch()

  const snackbarStatus = useSelector(snackbarOpenSelector)
  const snackbarType = useSelector(snackbarTypeSelector)
  const snackbarMessage = useSelector(snackbarMessageSelector)

  const handleClose = () => {
    dispatch(setSnackbarAction(false))
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackbarStatus}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        ContentProps={{
          classes: {
            root: snackbarType === "success" ? classes.success : classes.error
          }
        }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}

export default SnackbarComponent