import React, { useState, useEffect } from 'react';
import { MenuItem, Select, FormControl, InputLabel, Button, Divider } from '@material-ui/core';
import CsvUploader from './../views/CsvUploader';
import axios from 'axios';
import { BACKEND_DOMAIN } from './../data/constants/statuses';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { useSelector, useDispatch } from 'react-redux';
import { getUpdateDataApi } from './../api/updateDataApi';
import { addPromocodeApi, getAllManagerPromocodeBonusListApi, getAllManagersPromocodeListApi, manualUpdatePromocodeApi, manualUpdatePromocodeBonusApi } from './../api/promocodesApi';
import CsvUploaderDebet from '../views/CsvUploaderDebet';
import { calcDebetPerMonthYear } from '../data/helpers/realizationAndPaymentsHelper';
import { addDebetsApi } from '../api/debetsApi';

const UpdateDataContainer = () => {

  const [collectionType, setCollectionType] = useState('');
  const [existingItems, setExistingItems] = useState([]);

  const userData = useSelector(userDataSelector)
  const userRole = userData.role

  const { token } = userData

  const collectionTypes = [
    { value: 'debets', label: 'Debet' },
    { value: 'products-quantities', label: 'ProductQuantity' },
  ];

  useEffect(() => {
    const fetchExistingItems = async () => {
      if (collectionType) {
        const response = await axios.get(`${BACKEND_DOMAIN}/${collectionType}?_limit=-1`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setExistingItems(response.data);
      }
    };

    fetchExistingItems();
  }, [collectionType, token]);

  const handleCollectionChange = (e) => {
    const selectedCollectionType = e.target.value;
    setCollectionType(selectedCollectionType);
  };

  const handlePostHoroshopOrders = async () => {
    const typeValue = 'postHoroshopOrders';
    getUpdateDataApi(userData, typeValue);
  };

  const handlePostRozetkaOrders = async () => {
    const typeValue = 'postRozetkaOrders';
    getUpdateDataApi(userData, typeValue);
  };

  const handleCalcManagersProductQty = async () => {
    const typeValue = 'calcManagersProductQty';
    getUpdateDataApi(userData, typeValue);
  };

  const handleCalcPromocodesBonuses = async () => {
    const typeValue = 'calcPromocodesBonuses';
    getUpdateDataApi(userData, typeValue);
  };

  const handleCalcManagersSales = async () => {
    const typeValue = 'calcManagersSales';
    getUpdateDataApi(userData, typeValue);
  };
  const handleCalcManagersSalesPrev = async () => {
    const typeValue = 'calcManagersSales-prevMonth';
    getUpdateDataApi(userData, typeValue);
  };
  const handleCalcManagersSalesCurr = async () => {
    const typeValue = 'calcManagersSales-currMonth';
    getUpdateDataApi(userData, typeValue);
  };
  const handleStartAll= async () => {
    const typeValue = 'startAll';
    getUpdateDataApi(userData, typeValue);
  };


  const handleAddPromocodes = async () => {
    addPromocodeApi(userData)
  };

  const handleUpdatePromocodes = async () => {
    const promocodes = await getAllManagersPromocodeListApi(userData, 0)
    manualUpdatePromocodeApi(userData, promocodes)
  };

  const handleUpdatePromocodesBonuses = async () => {
    const currYear = 2024
    const currMonth = 2
    const bonuses = await getAllManagerPromocodeBonusListApi(userData, currYear, currMonth, 0)
    manualUpdatePromocodeBonusApi(userData, bonuses, currYear, currMonth)
  };

  const handleCalcDebetList = async () => {
    calcDebetPerMonthYear()
  };

  const handleAddDebets = async () => {
    await addDebetsApi(userData)
  };

  if (userRole != 'admin') {
    return ''
  }
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handlePostHoroshopOrders}>
        PostHoroshopOrders
      </Button>
      <Button variant="contained" color="primary" onClick={handlePostRozetkaOrders}>
        PostRozetkaOrders
      </Button>
      <Button variant="contained" color="primary" onClick={handleCalcManagersProductQty}>
        CalcManagersProductQty
      </Button>
      <Button variant="contained" color="primary" onClick={handleCalcPromocodesBonuses}>
        CalcPromocodesBonuses
      </Button>
      <Button variant="contained" color="primary" onClick={handleCalcManagersSales}>
        CalcManagersSales
      </Button>
      <Button variant="contained" color="primary" onClick={handleCalcManagersSalesPrev}>
        CalcManagersSalesPrev
      </Button>
      <Button variant="contained" color="primary" onClick={handleCalcManagersSalesCurr}>
        CalcManagersSalesCurr
      </Button>
      <Button variant="contained" color="primary" onClick={handleStartAll}>
      startAll
      </Button>
      <br />
      <br />
      <Button variant="contained" color="primary" onClick={handleAddPromocodes}
        style={{ marginLeft: '20px' }}
      >
        Add Promocodes
      </Button>
      <Button variant="contained" color="primary" onClick={handleUpdatePromocodes}
        style={{ marginLeft: '20px' }}
      >
        Update Promocodes
      </Button>
      <Button variant="contained" color="primary" onClick={handleUpdatePromocodesBonuses}
        style={{ marginLeft: '20px' }}
      >
        Update Promocodes Bonuses
      </Button>
      <Button variant="contained" color="primary" onClick={handleCalcDebetList}
        style={{ marginLeft: '20px' }}
      >
        Calc DebetList
      </Button>
      <Button variant="contained" color="primary" onClick={handleAddDebets}
        style={{ marginLeft: '20px' }}
      >
        Add Debets
      </Button>
      Last update front 130524
      <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
        <InputLabel id="collection-type-label">Collection Type</InputLabel>
        <Select
          labelId="collection-type-label"
          value={collectionType}
          onChange={handleCollectionChange}
          labelWidth={120}
          required
        >
          {collectionTypes.map((collectionType) => (
            <MenuItem key={collectionType.value} value={collectionType.value}>
              {collectionType.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {collectionType === 'products-quantities' && (
        <div>
          <h3>Загрузка данных с factQtyC</h3>
          <CsvUploader apiUrl={`${BACKEND_DOMAIN}/${collectionType}`} existingItems={existingItems} itemKey="itemId" token={token} collectionType={collectionType} factQtyField="factQtyC" />
          <h3>Загрузка данных с factQtyA</h3>
          <CsvUploader apiUrl={`${BACKEND_DOMAIN}/${collectionType}`} existingItems={existingItems} itemKey="itemId" token={token} collectionType={collectionType} factQtyField="factQtyA" />
          <h3>Загрузка данных с planQty</h3>
          <CsvUploader apiUrl={`${BACKEND_DOMAIN}/${collectionType}`} existingItems={existingItems} itemKey="itemId" token={token} collectionType={collectionType} factQtyField="planQty" />
        </div>
      )}
      {collectionType === 'debets' && (
        <div>
          <h3>Загрузка данных debet</h3>
          <CsvUploaderDebet apiUrl={`${BACKEND_DOMAIN}/${collectionType}`} existingItems={existingItems} itemKey="itemId" token={token} collectionType={collectionType} />
        </div>
      )}
    </div>
  );
};

export default UpdateDataContainer;
