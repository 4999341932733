import React from 'react';
import { TextField, FormControl, MenuItem, Button, Paper, Box, makeStyles } from '@material-ui/core';
import { monthList, yearList } from '../../data/constants/dates';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  selectMenu: {
    padding: '19px 8px',
  },
});

const FilterItemPanel = ({
  yearFilter,
  monthFilter,
  setYearFilter,
  setMonthFilter,
  doctorFilter,
  setDoctorFilter,
  filteredItems,
  promocodeFilter,
  setPromocodeFilter
}) => {

  const classes = useStyles();

  return (
    <Box style={{ padding: '0 0 0 10px' }}>
      <FormControl style={{ width: '200px' }}>
        <Autocomplete
          freeSolo
          id="autocomplete-doctor"
          disableClearable
          options={filteredItems.map((option) => ({ doctor: option.doctor, promocode: option.promocode }))}
          getOptionLabel={(option) => option && option.doctor ? option.doctor : ""}
          value={doctorFilter}
          onChange={(e, value) => {
            setDoctorFilter(value ? value.doctor : "");
            setPromocodeFilter(value ? value.promocode : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Введіть ПІБ"
              margin="normal"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      </FormControl>
      {/* <FormControl variant="outlined" margin="dense" style={{ width: '250px' }}>
        <Autocomplete
          freeSolo
          id="autocomplete-promocode"
          disableClearable
          options={filteredItems.map((option) => option.promocode)}
          value={promocodeFilter}
          onChange={(e, value) => setPromocodeFilter(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Введіть промокод"
              margin="normal"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      </FormControl> */}
      {/* <FormControl variant="outlined" margin="dense" style={{ margin: '8px 6px' }}>
        <TextField
          label="Рік"
          variant="outlined"
          margin="dense"
          value={yearFilter}
          onChange={(e) => setYearFilter(e.target.value)}
          select
          SelectProps={{
            classes: { selectMenu: classes.selectMenu }
          }}
        >
          {yearList.map(year => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl variant="outlined" margin="dense">
        <TextField
          label="Місяць"
          variant="outlined"
          margin="dense"
          value={monthFilter}
          onChange={(e) => setMonthFilter(e.target.value)}
          select
          SelectProps={{
            classes: { selectMenu: classes.selectMenu }
          }}
        >
          {monthList.map(month => (
            <MenuItem key={month} value={month}>{month}</MenuItem>
          ))}
        </TextField>
      </FormControl> */}
    </Box>
  );
};

export default FilterItemPanel;
