import HeaderLayout from "../layouts/HeaderLayout";
import DoctorsBonusListContainer from './../containers/DoctorsBonusListContainer';
import ReportDoctorsBonusListContainer from './../containers/ReportDoctorsBonusListContainer';

const DoctorsBonusReportPage = () => {

    return (
        <>
            <HeaderLayout />
            <ReportDoctorsBonusListContainer />
        </>
    )
}

export default DoctorsBonusReportPage