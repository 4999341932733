export const PROMOCODE_LIST_LOAD_SUCCESSFUL = 'PROMOCODE_LIST_LOAD_SUCCESSFUL'
export const CURRENT_PROMOCODE_ID_UPDATE = 'CURRENT_PROMOCODE_ID_UPDATE'
export const PROMOCODE_ITEM_UPDATE = 'PROMOCODE_ITEM_UPDATE'
export const PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL = 'PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL'
export const PROMOCODE_BONUS_ITEM_UPDATE = 'PROMOCODE_BONUS_ITEM_UPDATE'

const initialState = {
    items: [],
    currentId: null,
    bonuses: []
}

export const promocodeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PROMOCODE_LIST_LOAD_SUCCESSFUL:
            return { ...state, items: payload }
        case CURRENT_PROMOCODE_ID_UPDATE:
            return { ...state, currentId: payload }
        case PROMOCODE_ITEM_UPDATE:
            return {
                ...state,
                items: state.items.map(item =>
                    item.id === payload.id ? { ...item, ...payload.newData } : item
                )
            };
        case PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL:
            return { ...state, bonuses: payload }
        case PROMOCODE_BONUS_ITEM_UPDATE:
            return {
                ...state,
                bonuses: state.bonuses.map(item =>
                    item.id === payload.id ? { ...item, ...payload.newData } : item
                )
            };
        default:
            return state
    }
}

export default promocodeReducer
