import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DashboardTableRow from './DashboardTableRow';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { brandList } from '../data/constants/selects';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  tableRow: {
    backgroundColor: '#e8ebf7',
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  managerName: {
    marginTop: 20,
    marginLeft: 15
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
});

const AdminViewTableManagersTotalSale = (props) => {

  const {
    managers,
    userData,
    setLocation,
    year,
    brand,
    handleChangeYear,
    handleChangeBrand
  } = props
  const classes = useStyles();

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="brand label">Бренд</InputLabel>
          <Select
            value={brand}
            onChange={handleChangeBrand}
          >
            {brandList.map(item => {
              if (item.value === 'all') return
              return <MenuItem key={`brand-${item.value}`} value={item.value}>{item.label}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Рік</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead className={classes.stickyHeader}>
            <TableRow className={classes.tableRow}>
              <TableCell></TableCell>
              <TableCell>Січень</TableCell>
              <TableCell>Лютий</TableCell>
              <TableCell>Березень</TableCell>
              <TableCell>Квітень</TableCell>
              <TableCell>Травень</TableCell>
              <TableCell>Червень</TableCell>
              <TableCell>Липень</TableCell>
              <TableCell>Серпень</TableCell>
              <TableCell>Вересень</TableCell>
              <TableCell>Жовтень</TableCell>
              <TableCell>Листопад</TableCell>
              <TableCell>Грудень</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managers.map(item => {
              const managerSales = item.monthlySales.sort((a, b) => a.month > b.month)
              return (
                <React.Fragment key={item.managerName}>
                  <TableHead><Typography variant="h6" gutterBottom className={classes.managerName}>
                    {item.managerName}
                  </Typography>
                  </TableHead>
                  <React.Fragment>
                    <DashboardTableRow items={managerSales} title={'Інтернет-продажі з промокодами'} fieldName={'saleSum'} rowColor='blue' year={year} />
                    <DashboardTableRow items={managerSales} title={'Інтернет-продажі без промокодів'} fieldName={'otherSale'} rowColor='blue' year={year} />
                    <DashboardTableRow items={managerSales} title={'Аптеки'} fieldName={'aptekSale'} isEditable userData={userData} rowColor='blue' year={year} />
                    <DashboardTableRow items={managerSales} title={'Клініки'} fieldName={'clinicSale'} isEditable userData={userData} rowColor='blue' year={year} />
                    <DashboardTableRow items={managerSales} title={'Загальна сума продажів'} fieldName={'totalSaleSum'} rowColor='blue' year={year} />
                    <DashboardTableRow items={managerSales} title={'Бонус менеджера'} fieldName={'managerBonusSum'} rowColor='green' year={year} />
                    <DashboardTableRow items={managerSales} title={'Ставка'} fieldName={'minOklad'} isEditable userData={userData} rowColor='green' year={year} />
                    <DashboardTableRow items={managerSales} title={'Загальний дохід менеджера'} fieldName={'managerProfit'} rowColor='green' rowFont='bold' year={year} />
                  </React.Fragment>
                  <Divider />
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminViewTableManagersTotalSale