import SnackbarComponent from "../components/SnackbarComponent";
import BonusSystemContainer from "../containers/BonusSystemContainer";
import BonusSystemItemContainer from "../containers/BonusSystemItemContainer";
import HeaderLayout from "../layouts/HeaderLayout";

const BonusSystemItemPage = () => {

    return (
        <>
            <SnackbarComponent />
            <HeaderLayout />
            {/* <Container> */}
            <BonusSystemItemContainer />
            {/* </Container> */}
        </>
    )
}

export default BonusSystemItemPage