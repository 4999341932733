import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { NEW_PROMOCODE_STATUS } from '../data/constants/statuses';
import { loadAllManagersPromocodeListAction } from './../data/actions/promocodeActions';
import AdminEditTablePromocodeList from './../views/AdminEditTablePromocodeList';
import { allPromocodeListSelector } from './../data/selectors/promocodeSelector';
import { loadManagerListAction } from '../data/actions/managerListActions';
import { allManagerListSelector } from '../data/selectors/managerListSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminAllPromocodesContainer = () => {

    const dispatch = useDispatch()

    const [managerId, setManagerId] = useState(0)
    const [location, setLocation] = useState('uk-UA');

    const userData = useSelector(userDataSelector)
    const managerList = useSelector(allManagerListSelector)
    const allPromocodeList = useSelector(allPromocodeListSelector)

    const handleChangeManager = (event) => {
        setManagerId(event.target.value)
    }

    useEffect(() => {
        dispatch(loadAllManagersPromocodeListAction(userData, managerId, location))
    }, [managerId, location])

    useEffect(() => {
        dispatch(loadManagerListAction(userData));
        addUserLogDateApi(userData, '/admin/allPromocodes')
      }, []);

    return (
        <AdminEditTablePromocodeList
            items={allPromocodeList}
            userData={userData}
            status={NEW_PROMOCODE_STATUS}
            managerId={managerId}
            managerList={managerList}
            location={location}
            setLocation={setLocation}
            handleChangeManager={handleChangeManager}
        />
    )
}

export default AdminAllPromocodesContainer
