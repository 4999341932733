export const ORDER_LIST_LOAD_SUCCESSFUL = 'ORDER_LIST_LOAD_SUCCESSFUL'
export const CURRENT_MONTH_SET = 'CURRENT_MONTH_SET'

const initialState = {
    items: [],
    currentMonth: null
}

export const orderListReducer = (state = initialState, {type, payload}) => {
    switch (type) {
    case ORDER_LIST_LOAD_SUCCESSFUL:
        return {...state, items: payload}
    case CURRENT_MONTH_SET:
        return {...state, currentMonth: payload}
    default:
        return state
    }
}

export default orderListReducer
