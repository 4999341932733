import SnackbarComponent from "../components/SnackbarComponent";
import CalcFormContainer from "../containers/CalcFormContainer";
import HeaderLayout from "../layouts/HeaderLayout";

const CalcFormPage = () => {

    return (
        <>
            <SnackbarComponent />
            <HeaderLayout />
            <CalcFormContainer />
        </>
    )
}

export default CalcFormPage