import axios from "axios";
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const getProductListApi = async (userData) => {
  const { token } = userData

  const res = await axios
    .get(`${BACKEND_DOMAIN}/opt-products?_limit=-1`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        return response.data
      });
  return res;
}
