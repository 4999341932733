import { createSelector } from 'reselect'

export const productQtyListSelector = (state) => state.productQtyList.productQty

export const productQtyListTableSelector = (brand) => createSelector(
  [productQtyListSelector],
  (productQtyList) => {

    let productQtyArr = []
    let acceptablePercСomplRelativeTotalArr = []
    let filterByBrand;

    switch (brand) {
      case 'all':
        filterByBrand = productQtyList;
        break;
      case 'FEETCALM':
        filterByBrand = productQtyList.filter(item => item.productArticle.includes("FEETCALM"));
        break;
      case 'ELEGANT':
        filterByBrand = productQtyList.filter(item => !item.productArticle.includes("FEETCALM"));
        break;
      default:
        filterByBrand = [];
        break;
    }
    const articleListFilter = filterByBrand.filter(item => item.planQty > 0)
    const articleListLenght = articleListFilter.length

    filterByBrand.forEach(item => {

      const factQtyTotal = item.factQtyS + item.factQtyC + item.factQtyA

      const completionRateItem = item.planQty > 0 && factQtyTotal > 0 ? ((factQtyTotal / item.planQty).toFixed(4) * 100) : 0

      const completionRateRelativeTotal = completionRateItem / articleListLenght

      const threshold = 100 / articleListLenght

      const acceptablePercСomplRelativeTotal = completionRateRelativeTotal >= threshold ? threshold : completionRateRelativeTotal

      const newItem = { ...item, completionRateItem: completionRateItem, K: acceptablePercСomplRelativeTotal, L: threshold, factQtyTotal: factQtyTotal }
      productQtyArr.push(newItem)
      acceptablePercСomplRelativeTotalArr.push(acceptablePercСomplRelativeTotal)


    });
    const totalСomplPercent = acceptablePercСomplRelativeTotalArr.reduce((sum, i) => sum + i, 0).toFixed(2)
    // console.log("🚀 ~ file: productQtySelector.js:30 ~ totalСomplPercent:", totalСomplPercent)

    return { productQtyArr, totalСomplPercent }
  }
)


// for Dashboard bonus
export const productQtyListTableMonthSelector = createSelector(
  [productQtyListSelector],
  (productQtyList) => {
    // Создание объекта для хранения результирующих данных
    let result = {};

    // Группировка данных по годам и месяцам
    productQtyList.forEach(item => {
      const { month, year, manager } = item;
      if (!result[manager.id]) {
        result[manager.id] = {};
      }
      if (!result[manager.id][year]) {
        result[manager.id][year] = {};
      }
      if (!result[manager.id][year][month]) {
        result[manager.id][year][month] = [];
      }
      result[manager.id][year][month].push(item);
    });

    // Выполнение вычислений внутри каждой группы
    for (const managerId in result) {
      for (const year in result[managerId]) {
        for (const month in result[managerId][year]) {
          let productQtyArr = [];
          let acceptablePercСomplRelativeTotalArr = [];
          // Фильтрация данных для данного месяца
          const articleListFilter = result[managerId][year][month].filter(item => item.planQty > 0);
          const articleListLenght = articleListFilter.length;

          result[managerId][year][month].forEach(item => {
            const factQtyTotal = item.factQtyS + item.factQtyC + item.factQtyA;
            const completionRateItem = item.planQty > 0 && factQtyTotal > 0 ? ((factQtyTotal / item.planQty).toFixed(4) * 100) : 0;
            const completionRateRelativeTotal = completionRateItem / articleListFilter.length;
            const threshold = 100 / articleListLenght;
            const acceptablePercСomplRelativeTotal = completionRateRelativeTotal >= threshold ? threshold : completionRateRelativeTotal;
            const newItem = { ...item, completionRateItem, K: acceptablePercСomplRelativeTotal, L: threshold, factQtyTotal };
            productQtyArr.push(newItem);
            acceptablePercСomplRelativeTotalArr.push(acceptablePercСomplRelativeTotal);
          });

          // Вычисление общего процента выполнения для данного месяца и года
          const totalСomplPercent = acceptablePercСomplRelativeTotalArr.reduce((sum, i) => sum + i, 0).toFixed(2);

          // Замена данных в result на вычисленные результаты
          result[managerId][year][month] = { productQtyArr, totalСomplPercent };
        }
      }
    }

    return result;
  }
);




export const productSumQtyListTableSelector = (brand) => createSelector(
  [productQtyListSelector],
  (productQtyList) => {
    let filterByBrand;

    switch (brand) {
      case 'all':
        filterByBrand = productQtyList;
        break;
      case 'FEETCALM':
        filterByBrand = productQtyList.filter(item => item.productArticle.includes("FEETCALM"));
        break;
      case 'ELEGANT':
        filterByBrand = productQtyList.filter(item => !item.productArticle.includes("FEETCALM"));
        break;
      default:
        filterByBrand = [];
        break;
    }

    const mergeAndSum = (data) => {
      const result = [];

      data.forEach((item) => {
        const existingItem = result.find((r) => r.productArticle === item.productArticle);

        if (existingItem) {
          existingItem.factQtyTotal += item.factQtyTotal;
          existingItem.planQty += item.planQty;
          existingItem.factQtyS += item.factQtyS;
          existingItem.factQtyC += item.factQtyC;
          existingItem.factQtyA += item.factQtyA;
        } else {
          result.push({ ...item, id: `sum_${item.productArticle}` });

        }
      });

      return result;
    }

    const productData = mergeAndSum(filterByBrand);

    let productQtyArr = []
    let acceptablePercСomplRelativeTotalArr = []
    const articleListFilter = productData.filter(item => item.planQty > 0)
    const articleListLenght = articleListFilter.length

    productData.forEach(item => {

      const factQtyTotal = item.factQtyS + item.factQtyC + item.factQtyA

      const completionRateItem = item.planQty > 0 && factQtyTotal > 0 ? ((factQtyTotal / item.planQty).toFixed(4) * 100) : 0

      const completionRateRelativeTotal = completionRateItem / articleListLenght

      const threshold = 100 / articleListLenght

      const acceptablePercСomplRelativeTotal = completionRateRelativeTotal >= threshold ? threshold : completionRateRelativeTotal

      const newItem = { ...item, completionRateItem: completionRateItem, K: acceptablePercСomplRelativeTotal, L: threshold, factQtyTotal: factQtyTotal }
      productQtyArr.push(newItem)
      acceptablePercСomplRelativeTotalArr.push(acceptablePercСomplRelativeTotal)


    });
    const totalСomplPercent = acceptablePercСomplRelativeTotalArr.reduce((sum, i) => sum + i, 0).toFixed(2)

    return { productQtyArr, totalСomplPercent }
  }
)

