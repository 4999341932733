import { getBonusDataForPeriod, getOrderDataForPeriod, getPromocodeData } from "../../api/bonusSystemApi"
import { addUserLogDateApi } from "../../api/logDataApi"
import { updatePromocodeBonusByIdApi, updatePromocodeByIdApi } from "../../api/promocodesApi"
import { ORDER_LIST_LOAD_SUCCESSFUL } from "../reducers/orderListReducer"
import { PROMOCODE_BONUS_ITEM_UPDATE, PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL, PROMOCODE_ITEM_UPDATE, PROMOCODE_LIST_LOAD_SUCCESSFUL } from "../reducers/promocodeReducer"
import { setSnackbarAction } from "./snackbarActions"

export const loadBonusDataByPeriodAction = (userData, year, month, selectedManagerId, promocodeFilter) => {
  return async (dispatch) => {
    const data = await getBonusDataForPeriod(userData, year, month, selectedManagerId, promocodeFilter)
    dispatch({
      type: PROMOCODE_BONUS_LIST_LOAD_SUCCESSFUL,
      payload: data,
    })
  }
}

export const loadOrderDataByPeriodAction = (userData, year, month, selectedManagerId, promocodeFilter) => {
  return async (dispatch) => {
    const data = await getOrderDataForPeriod(userData, year, month, selectedManagerId, promocodeFilter)
    dispatch({
      type: ORDER_LIST_LOAD_SUCCESSFUL,
      payload: data,
    })
  }
}

export const loadPromocodeDataAction = (userData) => {
  return async (dispatch) => {
    const data = await getPromocodeData(userData)
    dispatch({
      type: PROMOCODE_LIST_LOAD_SUCCESSFUL,
      payload: data,
    })
  }
}

export const updateBonusItemAction = (id, data, userData, promocode) => {
  return async (dispatch) => {
    const res = await updatePromocodeBonusByIdApi(id, data, userData)
    if (res.status !== 200) return dispatch(setSnackbarAction(true, "error", "Помилка! Дані не збережені."))
    if (res.status === 200) {
      dispatch({
        type: PROMOCODE_BONUS_ITEM_UPDATE,
        payload: { id, newData: res.data }
      });
      dispatch(setSnackbarAction(true, "success", "Дані збережені."))
      addUserLogDateApi(userData, `/updateBonusItem/${promocode}`, { id, data })
      return res.data
    }
  }
}

export const updatePromocodeItemAction = (id, data, userData) => {
  return async (dispatch) => {
    const res = await updatePromocodeByIdApi(id, data, userData)
    if (res.status !== 200) return dispatch(setSnackbarAction(true, "error", "Помилка! Дані не збережені."))
    if (res.status === 200) {
      dispatch({
        type: PROMOCODE_ITEM_UPDATE,
        payload: { id, newData: res.data }
      });
      dispatch(setSnackbarAction(true, "success", "Дані збережені."))
      addUserLogDateApi(userData, '/updatePromocodeItem', { id, data })
      return res.data
    }
  }
}