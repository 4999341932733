// RegionRow.js

import React from 'react';
import BonusPaymentRowItem from './BonusPaymentRowItem';

const BonusPaymentRow = ({ tableData, userData, showPreviousMonths }) => {

  return (
    tableData.map((row, index) => {
      if(!row.toPaySum) return
      return <BonusPaymentRowItem
        key={row.id}
        item={row}
        userData={userData}
        showPreviousMonths={showPreviousMonths}
      />
    })
  )
};

export default BonusPaymentRow;