import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import PromocodeBonusReadRow from './PromocodeBonusReadRow';
import PromocodeBonusEditableRow from './PromocodeBonusEditableRow';
import { updatePromocodeItemAction, updatePromocodeBonusAction } from '../data/actions/promocodeActions';
import { ACTIVE_PROMOCODE_STATUS, NEW_PROMOCODE_STATUS } from '../data/constants/statuses';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    position: 'relative',
    top: -11
  },
  tableCell: {
    padding: '6px 8px 6px 8px'
  },
  alarmColor: {
    color: '#ff0000',
    padding: '6px 8px 6px 8px',
    fontWeight: 'bold',
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 75
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 64,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
});

const EditTableDoctorsBonusList = (props) => {
  const { items, userData, newPromocodeBonusAllSum } = props

  const classes = useStyles();

  const dispatch = useDispatch()

  const [editFormData, setEditFormData] = useState({
  });

  const [editItemId, setEditItemId] = useState(null);

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const bonusTotalNumber = Number(editFormData.bonusSum) + Number(editFormData.bonusExtra)

    const editedItem = {
      bonusSum: editFormData.bonusSum,
      bonusExtra: editFormData.bonusExtra,
      bonusTotal: bonusTotalNumber

    };

    dispatch(updatePromocodeBonusAction(editItemId, editedItem, userData));
    setEditItemId(null);
  };
  const handleActivateClick = (event, item, itemId) => {
    event.preventDefault();

    const editedItem = {
      activated: true,
    };

    dispatch(updatePromocodeBonusAction(itemId, editedItem, userData));
    setEditItemId(null);
  };

  const handleEditClick = (event, item, itemId) => {
    event.preventDefault();
    setEditItemId(itemId);

    const formValues = {
      manager: item.manager.managerName,
      promocode: item.promocode,
      doctor: item.doctor,
      clinic: item.clinic,
      city: item.city,
      region: item.region,
      phone: item.phone,
      cardNumber: item.cardNumber,
      bonusSum: item.bonusSum,
      bonusExtra: item.bonusExtra
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditItemId(null);
  };

  const isFinLimitAlarm = newPromocodeBonusAllSum.allBonusExtra > newPromocodeBonusAllSum.managerSalesFinLimit ? true : false
  const finLImitText = " - Сума перевищує фінліміт!"

  return (
    <TableContainer component={Paper} className={classes.container} elevation={0}>
      <Table className={classes.table} size="small" >
        <TableHead className={classes.stickyHeader}>
          <TableRow>
            <TableCell className={classes.tableCell}>Промокод</TableCell>
            <TableCell className={classes.tableCell}>ПІБ лікаря</TableCell>
            <TableCell className={classes.tableCell}>Клініка</TableCell>
            <TableCell className={classes.tableCell}>Місто</TableCell>
            <TableCell className={classes.tableCell}>Область</TableCell>
            <TableCell className={classes.tableCell}>Номер телефону</TableCell>
            <TableCell className={classes.tableCell}>Номер карти</TableCell>
            <TableCell className={classes.tableCell}>Місяць</TableCell>
            <TableCell className={classes.tableCell}>Бонуси по промокодам</TableCell>
            <TableCell className={classes.tableCell}>Ввести дод. бонуси</TableCell>
            <TableCell className={classes.tableCell}>Сума всіх бонусів</TableCell>
            <TableCell className={classes.tableCell}>Фінліміт</TableCell>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}></TableCell>
            <TableCell className={classes.tableCell}>{newPromocodeBonusAllSum.month}</TableCell>
            <TableCell className={classes.tableCell}>{newPromocodeBonusAllSum.allBonusSum}</TableCell>
            <TableCell className={isFinLimitAlarm ? classes.alarmColor : classes.tableCell}>{newPromocodeBonusAllSum.allBonusExtra}
              {isFinLimitAlarm && finLImitText}
            </TableCell>
            <TableCell className={classes.tableCell}>{newPromocodeBonusAllSum.allBonusTotal}</TableCell>
            <TableCell className={classes.tableCell}>{newPromocodeBonusAllSum.managerSalesFinLimit}</TableCell>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.sort((a, b) => (b.bonusSum - a.bonusSum)).map(item => {
            // console.log("🚀 ~ file: ViewTableDoctorsBonusList.js ~ line 108 ~ ViewTableDoctorsBonusList ~ item", item)
            return (
              <>
                {editItemId === item.id ? (
                  <PromocodeBonusEditableRow
                    editFormData={editFormData}
                    item={item}
                    handleEditFormChange={handleEditFormChange}
                    handleEditFormSubmit={handleEditFormSubmit}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <PromocodeBonusReadRow
                    item={item}
                    itemId={item.id}
                    handleEditClick={handleEditClick}
                    handleActivateClick={handleActivateClick}
                    isEditable
                  // handleDeleteClick={handleDeleteClick}
                  />
                )}
              </>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EditTableDoctorsBonusList