import axios from 'axios';
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const getProductQtyListApi = async (data) => {
    const { userData, year, month, managerIdData } = data
    const { token, settingsData } = userData

    // const monthRes = month ? month : settingsData.monthData
    const monthRes = month ? `&month=${month}` : (month === 0 ? '' : `&month=${settingsData.monthData}`);

    const yearRes = year ? year : settingsData.yearData
    const manager = managerIdData === 0 ? '' : `&manager=${managerIdData}`

    const res = axios
        .get(`${BACKEND_DOMAIN}/products-quantities?_limit=-1${manager}&year=${yearRes}${monthRes}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updateProductQtyByIdApi = async (data) => {
    const { userData, editedData } = data
    const { token } = userData
    const { id, planQty } = editedData
    const res = axios
        .put(`${BACKEND_DOMAIN}/products-quantities/${id}`, {planQty: planQty}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response.status
        });
    return res;
}