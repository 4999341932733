import HeaderLayout from "../layouts/HeaderLayout";
import DoctorsBonusListContainer from './../containers/DoctorsBonusListContainer';
import ReportDoctorsBonusListContainer from './../containers/ReportDoctorsBonusListContainer';
import OrderListPerMonthContainer from './../containers/OrderListPerMonthContainer';

const OrderListReportPage = () => {

    return (
        <>
            <HeaderLayout />
            <OrderListPerMonthContainer />
        </>
    )
}

export default OrderListReportPage