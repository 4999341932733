// Regionitem.js

import React, { useEffect, useState, useRef } from 'react';
import { TableCell, Checkbox, TextField, TableRow } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useDispatch } from 'react-redux';
import { updateBonusItemAction } from '../../data/actions/bonusSystemActions';
import { Link, useHistory, useLocation } from 'react-router-dom';

const BonusPaymentRowItem = React.memo(({ item, userData, showPreviousMonths }) => {

  const [inputData, setInputData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const bonusRecommendedRef = useRef({});
  const appointmentsRef = useRef({});

  const handleInputChange = (itemId, field, event) => {
    const value = event.target.value;
    if (value === '' || !isNaN(value)) {
      setInputData(prevData => ({
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          [field]: value
        }
      }));
    }
  };

  const handleSave = (itemId, field, newData) => {
    const editedItem = {
      ...newData[itemId],
      [field]: newData[itemId][field] !== undefined ? newData[itemId][field] : 0
    };
    dispatch(updateBonusItemAction(itemId, editedItem, userData), () => {
      // Clear data after saving, if necessary
      setInputData(prevData => {
        const { [field]: removed, ...rest } = prevData[itemId];
        return {
          ...prevData,
          [itemId]: rest
        };
      });
    });

    // Remove focus
    if (field === 'bonusRecommended') {
      bonusRecommendedRef.current[itemId].blur();
    } else if (field === 'appointments') {
      appointmentsRef.current[itemId].blur();
    }
  };

  // const handleKeyPress = (itemId, field, e) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault();
  //     handleSave(itemId, field, inputData);
  //   }
  // };

  const handleCheckboxChange = (itemId, field, event) => {
    const isChecked = event.target.checked;

    setInputData(prevData => {
      const newData = {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          [field]: isChecked,
        }
      };
      handleSave(itemId, field, newData);
      return newData;
    });
    
    // Update isPayed for previous months
    item.previousData.forEach(prevData => {
      const prevBonusId = Object.keys(prevData).find(key => key.includes('_id'));
      const itemId = prevData[prevBonusId]
      const newData = {
        [itemId]: {
          isPayed: isChecked
        }
      };

      handleSave(itemId, 'isPayed', newData);
    });
  };

  const isDisabledColor = 'rgb(119, 119, 119)'

  const itemId = item.id;

  const isCheckboxChecked = item.isPayed || false;
  const isToPay = item.toPay || false;
  return (
    <TableRow
      key={itemId}
      style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}
    >
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.region}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.city}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        {item.promocode.startsWith('t_') ? '-' : item.promocode}
      </TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        {/* <Link to={{
          pathname: userData.role !== 'Manager1' ? "/admin/bonusSystemItem" : "/bonusSystemItem",
          state: {
            doctor: item.doctor, promocode: item.promocode, year: item.bonusYear, month: item.bonusMonth,
            from: location.pathname
          }
        }}
          title='Перейти в карточку спеціаліста'
        > */}
        {item.doctor}
        {/* </Link> */}
      </TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.doctorCategory}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.phone}</TableCell>
      <TableCell
        style={{ color: (!isToPay && !item.isPayed) ? 'orange' : '#00bb17' }}
        title={!isToPay ? 'Ще не підтверджено' : 'Підтверджено'}
      >{item.toPaySum}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black', width: 100 }}>{item.bonusMethod}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>{item.deliveryPlace}</TableCell>
      <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        <Checkbox
          checked={isCheckboxChecked}
          disabled={!isToPay || isCheckboxChecked || item.toPaySum < 500}
          title={!isToPay ? 'Ще не підтверджено' : 'Підтверджено'}
          onChange={(e) => handleCheckboxChange(itemId, 'isPayed', e)}
        />
      </TableCell>
      {/* <TableCell style={{ color: isCheckboxChecked ? isDisabledColor : 'black' }}>
        <DescriptionIcon />
      </TableCell> */}
    </TableRow>
  )
});

export default BonusPaymentRowItem;