
import { getManagerDebetListApi } from './../../api/debetsApi';
import { DEBET_LIST_LOAD_SUCCESSFUL } from './../reducers/debetsReducer';

export const loadManagerDebetListAction = (userData, year, month, managerId) => {
  return async (dispatch) => {
    const data = await getManagerDebetListApi(userData, year, month, managerId)
    // console.log("🚀 ~ file: debetsActions.js:8 ~ return ~ data:", data)
    dispatch({
      type: DEBET_LIST_LOAD_SUCCESSFUL,
      payload: data,
    })
  }
}