import axios from 'axios';
import { BACKEND_DOMAIN } from './../data/constants/statuses';
import { calcDebetPerMonthYear } from '../data/helpers/realizationAndPaymentsHelper';

export const getManagerDebetListApi = async (userData, filterYear, filterMonth, managerId) => {
  const { token, managerLocale, settingsData } = userData

  const month = filterMonth ? filterMonth : settingsData.monthData
  const year = filterYear ? filterYear : settingsData.yearData
  const manager = managerId === 0 ? '' : `&manager=${managerId}`

  const request = filterMonth ? `${manager}&month=${month}&year=${year}` : ''


  const res = axios
    .get(`${BACKEND_DOMAIN}/debets?_limit=-1${request}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        return response.data
      });
  return res;
}

export const addDebetsApi = async (userData) => {
  const { token } = userData;
  const debetList = await getAllDebetListApi(userData);
  const debets = await calcDebetPerMonthYear(debetList);

  const MAX_SIMULTANEOUS_REQUESTS = 5; // Максимальное количество одновременных запросов
  let activeRequests = 0;
  let i = 0;

  const sendDebet = async (debet) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/debets`, debet, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Debet added successfully', response);
    } catch (error) {
      console.error('Error adding debet', error);
    } finally {
      activeRequests--;
      processQueue(); // Попытка обработать следующий элемент в очереди
    }
  };

  const processQueue = () => {
    while (activeRequests < MAX_SIMULTANEOUS_REQUESTS && i < debets.length) {
      sendDebet(debets[i]);
      activeRequests++;
      i++;
    }
    if (i >= debets.length && activeRequests === 0) {
      console.log('All debets have been processed');
    }
  };

  processQueue(); // Начало обработки очереди
};


export const getAllDebetListApi = async (userData) => {
  const { token } = userData

  const currMonth = new Date().getMonth();

  const res = axios
    .get(`${BACKEND_DOMAIN}/debets?_limit=-1`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        return response.data
      });
  return res;
}