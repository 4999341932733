import SnackbarComponent from "../components/SnackbarComponent";
import BonusSystemPaymentsContainer from "../containers/BonusSystemPaymentsContainer";
import AdminHeaderLayout from "../layouts/AdminHeaderLayout";

const AdminBonusSystemPaymentsPage = () => {

    return (
        <>
            <SnackbarComponent />
            <AdminHeaderLayout />
            {/* <Container> */}
            <BonusSystemPaymentsContainer />
            {/* </Container> */}
        </>
    )
}

export default AdminBonusSystemPaymentsPage