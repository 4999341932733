export const PRODUCT_QTY_LIST_LOAD_SUCCESSFUL = 'MANAGER_PRODUCT_QTY_LIST_LOAD_SUCCESSFUL'

const initialState = {
    productQty: []
}

export const productQtyListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PRODUCT_QTY_LIST_LOAD_SUCCESSFUL:
            return { ...state, productQty: payload }
        default:
            return state
    }
}