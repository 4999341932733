import realizationList from '../../api/realizationList.json';
import paymentList from '../../api/paymentList.json';
import contragentList from '../../api/contragentList.json';

export const calcDebetPerMonthYear = async (debetList) => {
  // console.log("🚀 ~ calcDebetPerMonthYear ~ debetList:", debetList);

  // 1. Создать список контрагентов newContragentList из массивов realizationList и paymentList (contragentId).
  const newContragentList = [
    ...new Set([
      ...realizationList.map(item => item.contragentId),
      ...paymentList.map(item => item.contragentId)
    ])
  ];

  // 2. Создать функцию getLastTotalDebt для получения последнего существующего долга каждого контрагента из newContragentList.
  const getLastTotalDebt = (debetList, contragentList) => {
    const contragentDebts = {};

    debetList.forEach(item => {
      const contragent = item.contragentId;
      if (contragentList.includes(contragent)) {
        if (!contragentDebts[contragent]) {
          contragentDebts[contragent] = [];
        }
        contragentDebts[contragent].push(item);
      }
    });

    const lastTotalDebts = {};
    for (const contragent in contragentDebts) {
      const debts = contragentDebts[contragent];
      const lastDebt = debts.reduce((latest, current) => {
        return new Date(current.year, current.month - 1) > new Date(latest.year, latest.month - 1) ? current : latest;
      });
      lastTotalDebts[contragent] = lastDebt.totalDebt;
    }

    return lastTotalDebts;
  };

  const lastTotalDebts = getLastTotalDebt(debetList, newContragentList);

  // 3. Посчитать для каждого контрагента из newContragentList сумму реализаций и оплат и вывести в массив объектов [{contragentId, realization, payed, debtPrevMonth, totalDebt, contragent, managerName }]
  const contragentSums = newContragentList.map(contragentId => {
    const realizationSum = realizationList
      .filter(item => item.contragentId === contragentId)
      .reduce((sum, item) => sum + item.sum, 0);

    const payedSum = paymentList
      .filter(item => item.contragentId === contragentId)
      .reduce((sum, item) => sum + item.sum, 0);

    const debtPrevMonth = lastTotalDebts[contragentId] || 0;
    const totalDebt = realizationSum - payedSum + debtPrevMonth;

    const contragentInfo = contragentList.find(item => item.contragentId === contragentId);
    const itemYear = realizationList[0].year;
    const itemMonth = realizationList[0].month === paymentList[0].month ? realizationList[0].month : null;
    const contragent = contragentInfo ? contragentInfo.contragent : '';
    const managerId = contragentInfo ? contragentInfo.manager : '';
    const itemId = contragentInfo ? `${contragentId}-${itemMonth}-${itemYear}` : '';

    return { itemId: itemId, contragentId, realization: realizationSum, payed: payedSum, debtPrevMonth, totalDebt, contragent, manager: managerId, month: itemMonth, year: itemYear };
  });

  // console.log("🚀 ~ calcDebetPerMonthYear ~ contragentSums:", contragentSums);

  return contragentSums;
};