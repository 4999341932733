import axios from 'axios';
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const sendToDataApi = async (userData, transformedObject) => {
    const { token } = userData;

    try {
        const res = await axios.post(`${BACKEND_DOMAIN}/process-array`, transformedObject, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(res.status);
        return res;
    } catch (error) {
        if (!error.response) {
            // Network error (e.g., net::ERR_INTERNET_DISCONNECTED)
            console.error('Network error:', error.message);
            return { error: 'Network error. Please check your internet connection.' };
        } else {
            // HTTP error (e.g., 4xx, 5xx responses)
            console.error('HTTP error:', error.response.status, error.response.data);
            return { error: error.response.data || 'HTTP error occurred.' };
        }
    }
};