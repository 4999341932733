import { createPromocodeCommentItemApi, getPromocodeCommentItemApi } from "../../api/promocodeCommentsApi"
import { PROMOCODE_COMMENT_ITEM_CREATE_SUCCESSFUL, PROMOCODE_COMMENT_ITEM_LOAD_SUCCESSFUL } from "../reducers/promocodeCommentsReducer"
import { setSnackbarAction } from "./snackbarActions"

export const loadPromocodeCommentItemAction = (userData, promocode) => {
  return async (dispatch) => {
    const data = await getPromocodeCommentItemApi(userData, promocode)
    dispatch({
      type: PROMOCODE_COMMENT_ITEM_LOAD_SUCCESSFUL,
      payload: data,
    })
  }
}

export const createPromocodeCommentItemAction = (userData, data) => {
  return async (dispatch) => {
    const res = await createPromocodeCommentItemApi(userData, data)
    if (res.status !== 200) return dispatch(setSnackbarAction(true, "error", "Помилка! Дані не збережені."))
    if (res.status === 200) {
      dispatch({
        type: PROMOCODE_COMMENT_ITEM_CREATE_SUCCESSFUL,
        payload: res.data
      });
      dispatch(setSnackbarAction(true, "success", "Дані збережені."))
      return res.data
    }
  }
}