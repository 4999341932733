import HeaderLayout from "../layouts/HeaderLayout";
import DoctorsBonusListContainer from '../containers/DoctorsBonusListContainer';
import ReportDoctorsBonusListContainer from '../containers/ReportDoctorsBonusListContainer';
import OrderListPerMonthContainer from '../containers/OrderListPerMonthContainer';
import AdminHeaderLayout from '../layouts/AdminHeaderLayout';
import AdminOrderListPerMonthContainer from '../containers/AdminOrderListPerMonthContainer';
import AdminProductPlanContainer from "../containers/AdminProductPlanContainer";
import SnackbarComponent from '../components/SnackbarComponent';

const AdminProductQtyListReportPage = () => {

    return (
        <>
            <SnackbarComponent />
            <AdminHeaderLayout />
            <AdminProductPlanContainer />
        </>
    )
}

export default AdminProductQtyListReportPage