import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { allManagerListSelector } from './../data/selectors/managerListSelector';
import { loadManagerListAction } from './../data/actions/managerListActions';
import { loadManagerDebetListAction } from './../data/actions/debetsActions';
import { debetListSelector } from './../data/selectors/debetListSelector';
import AdminViewTableDebetList from './../views/AdminViewTableDebetList';
import { addUserLogDateApi } from '../api/logDataApi';
import ViewTableDebetList from '../views/ViewTableDebetList';

const DebetListReportContainer = () => {

    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const managerId = userData.managerId
    const [month, setMonth] = useState(currentMonth === 12 ? 1 : currentMonth + 1)
    const [year, setYear] = useState(currentMonth === 12 ? currentYear + 1 : currentYear)
    const debetList = useSelector(debetListSelector)

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    useEffect(() => {
        dispatch(loadManagerDebetListAction(userData, year, month, managerId))
        addUserLogDateApi(userData, '/debetListReport')

    }, [month, year])

    return (
        <ViewTableDebetList
            data={debetList}
            month={month}
            year={year}
            managerId={managerId}
            userData={userData}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
        />

    )
}

export default DebetListReportContainer
