export const MANAGER_LIST_LOAD_SUCCESSFUL = 'MANAGER_LIST_LOAD_SUCCESSFUL'
export const MAIN_MANAGER_LIST_LOAD_SUCCESSFUL = 'MAIN_MANAGER_LIST_LOAD_SUCCESSFUL'
export const MANAGER_SALE_LIST_LOAD_SUCCESSFUL = 'MANAGER_SALE_LIST_LOAD_SUCCESSFUL'

const initialState = {
    managerList: [],
    sales: []
}

export const managerListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case MANAGER_LIST_LOAD_SUCCESSFUL:
            return { ...state, managerList: payload }
        case MAIN_MANAGER_LIST_LOAD_SUCCESSFUL:
            return { ...state, managerList: payload }
        case MANAGER_SALE_LIST_LOAD_SUCCESSFUL:
            return { ...state, sales: payload }
        default:
            return state
    }
}

export default { managerListReducer }
